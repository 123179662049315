<template>
  <div
    class="
      card
      rounded-lg
      shadow
      border border-white
      bg-white
      h-100
      mb-0
      pb-3
      overflow-auto
    "
  >
    <div class="card-header bg-transparent">
      <div class="row">
        <div class="col-4">
          <h5 class="ml-2 text-secondary font-weight-light">
            <span class="fa fa-file-alt"></span> Facture Client :
            <span class="ml-2 text-success"> {{ nomBon }} </span>
          </h5>

          <div class="mt-1 ml-2 d-inline text-secondary font-weight-light">
            Client : <span class="d-inline text-info"> {{ nClient }} </span>
          </div>
          <div class="mt-1 ml-2 d-inline text-secondary font-weight-light">
            ICE : <span class="d-inline text-info"> {{ ice }} </span>
          </div>
          <div class="mt-1 ml-2  text-secondary font-weight-light">
            Dépôt : <span class="d-inline text-info"> {{ nDep }} </span>

            Mode : <span class="ml-2 d-inline text-info"> {{ mode }} </span>
          </div>

          <div class="ml-2 custom-control custom-switch">
            <input
              disabled
              type="checkbox"
              class="custom-control-input"
              id="is_stock"
              v-model="l_is_stock"
              name="is_stock"
              value="true"
              unchecked-value="false"
            />

            <label
              class="custom-control-label text-secondary font-weight-light"
              for="is_stock"
              >Facture Pérsonnalisée</label
            >
          </div>
        </div>

        <div class="col-8">
          <b-button
            pill
            size="sm"
            class="m-1 float-right text-secondary"
            variant="link"
            @click="NewBon"
          >
            <i class="fa fa-home fa-2x"></i>
          </b-button>

          <b-button
            pill
            size="sm"
            class="m-2 float-right"
            variant="danger"
            @click="del_BonClick"
          >
            <i class="fa fa-trash-alt"></i>
            <span> Supprimer</span>
          </b-button>

          <b-button
            v-if="d_gst_fact_val == true"
            pill
            size="sm"
            class="m-2 float-right"
            variant="info"
            @click="clickValiderBon"
          >
            <i class="fa fa-check"></i>
            <span> Valider</span>
          </b-button>

          <b-button
            v-if="d_gst_fact_val == true"
            pill
            size="sm"
            class="m-2 float-right"
            variant="success"
            @click="fetchFormComptes_regler"
          >
            <i class="fa fa-check"></i>
            <span> Valider Et Régler</span>
          </b-button>

          <b-button
            v-if="d_gst_fact_val == true"
            pill
            size="sm"
            class="m-2 float-right"
            variant="secondary"
            @click="clic_export"
          >
            <i class="fa fa-print"></i>
            <span> Impr.</span>
          </b-button>


                <b-button v-if="d_is_entete==true" pill size="sm"  class="m-2 float-right" variant="info" @click="exportDoxEntet">
                    <i class="fa fa-print"></i>
                    <span> Impr.S En-tête</span> 
                </b-button>

                <b-button v-if="d_is_print_pdf==true" pill size="sm"  class="m-2 float-right" variant="warning" @click="export_pdf">
                    <i class="fa fa-print"></i>
                    <span> Impr.PDF</span> 
                </b-button>

                

        </div>
      </div>
    </div>

    <div class="row card-body h-100 p-0 m-0">
      <div class="p-0">
        <b-button
          v-b-toggle.collap-categ.collap-articl.collap-form
          size="lg"
          class="ml-0 mr-0 pl-1 pr-1 float-left text-secondary"
          variant="link"
        >
          <i class="fa fa-bars"></i>
        </b-button>
      </div>

      <b-collapse
        id="collap-categ"
        visible
        class="col-2 h-100 mr-3 ml-0 mt-2 p-0"
      >
        <div class="card border-success h-100 overflow-auto">
          <div class="card-header bg-success border-success p-1">
            <b-form-input
              v-model="searchCateg"
              class="border-info"
              id="search-cat"
              size="sm"
              placeholder="Chercher ..."
              type="search"
            ></b-form-input>

            <b-button
              @click="AfficherTousArts"
              size="sm"
              class="ml-1 mb-1 mt-1 p-0 text-white text-decoration-none"
              variant="link"
            >
              <i class="fa fa-list-alt mr-1"></i>
              Tous Les Articles
            </b-button>
          </div>

          <b-list-group flush>
            <b-list-group-item
              v-for="item in filterCategorie"
              :key="item.id"
              button
              class="p-1"
              variant="success"
              @click="cat_click(item.id)"
            >
              {{ item.nom }}
              <p>
                <small>{{ item.description }}</small>
              </p>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-collapse>

      <b-collapse id="collap-articl" visible class="col-2 h-100 mr-0 mt-2 p-0">
        <div class="card border-info h-100 overflow-auto">
          <div class="card-header bg-info border-info p-1">
            <b-form-input
              v-model="serchArt"
              class="border-info"
              id="search-art"
              size="sm"
              placeholder="Chercher ..."
              type="search"
            ></b-form-input>

            <div class="custom-control custom-switch form-control-sm ml-1">
              <input
                type="checkbox"
                class="custom-control-input"
                id="isCodeBarreSearch"
                v-model="isCodeBarreSearch"
                name="isCodeBarreSearch"
                value="true"
                unchecked-value="false"
              />

              <label
                class="custom-control-label text-white mb-0"
                for="isCodeBarreSearch"
                >Code Barre</label
              >
            </div>
          </div>

          <b-list-group flush>
            <b-list-group-item
              v-for="item in filterArticle"
              :key="item.id"
              button
              class="p-1"
              variant="info"
              @click="art_click(item.id)"
            >
              {{ item.ref }}
              <p>
                <small>{{ item.description }}</small>
              </p>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-collapse>

      <div class="col h-100 overflow-auto mt-2 mr-4 ml-1">
        <b-collapse id="collap-form" visible>
          <form @submit.prevent="handleSubmit">
            <div class="row">
              <div class="form-group col">
                <label for="ref" class="form-control-sm mb-0">Référence</label>
                <input
                  type="text"
                  v-model="itemForm.ref"
                  id="ref"
                  name="ref"
                  class="form-control form-control-sm"
                  :class="{
                    'is-invalid': isSubmitted && $v.itemForm.ref.$error,
                  }"
                />
                <div
                  v-if="isSubmitted && !$v.itemForm.ref.required"
                  class="invalid-feedback"
                >
                  Ce champ est obligatoire !!
                </div>
              </div>

              <div class="form-group col">
                <label for="lot_id" class="form-control-sm mb-0">Lot :</label>

                <select
                  class="form-control form-control-sm"
                  id="lot_id"
                  v-model="itemForm.lot_id"
                  :class="{
                    'is-invalid': isSubmitted && $v.itemForm.lot_id.$error,
                  }"
                >
                  <option
                    v-for="item in ListLots"
                    :key="item.id"
                    v-bind:value="item.id"
                  >
                    {{ item.nom }} - {{ item.qte }}
                  </option>
                </select>
                <div
                  v-if="isSubmitted && !$v.itemForm.lot_id.required"
                  class="invalid-feedback"
                >
                  Ce champ est obligatoire !!
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col">
                <label for="qte_min" class="form-control-sm mb-0"
                  >Quantité :
                </label>
                                                    <b-button @click="add_calc_click($event.target)"  size="sm"  class="ml-1 mr-0 float-left p-1 text-decoration-none" variant="link">
                                    <i class="fa fa-calculator"></i>                
                                    </b-button> 
                <input
                  type="text"
                  v-model="itemForm.qte"
                  id="qte_min"
                  name="qte_min"
                  class="form-control form-control-sm"
                  :class="{
                    'is-invalid': isSubmitted && $v.itemForm.qte.$error,
                  }"
                />
                <div
                  v-if="isSubmitted && !$v.itemForm.qte.required"
                  class="invalid-feedback"
                >
                  Ce champ est obligatoire !!
                </div>
                <div
                  v-if="isSubmitted && !$v.itemForm.qte.decimal"
                  class="invalid-feedback"
                >
                  Entrez une valeur numérique !!
                </div>
              </div>

              <div class="form-group col">
                <label for="unite" class="form-control-sm mb-0">Unité</label>
                <input
                  type="text"
                  v-model="itemForm.unite"
                  id="unite"
                  name="unite"
                  class="form-control form-control-sm"
                />
              </div>
            </div>

            <div class="row">
              <div class="form-group col">
                <label for="prix" class="form-control-sm mb-0"
                  >Prix TTC :</label
                >
                <input
                  type="text"
                  v-model="itemForm.prix"
                  id="prix"
                  name="prix"
                  class="form-control form-control-sm"
                  :class="{
                    'is-invalid': isSubmitted && $v.itemForm.prix.$error,
                  }"
                />
                <div
                  v-if="isSubmitted && !$v.itemForm.prix.required"
                  class="invalid-feedback"
                >
                  Ce champ est obligatoire !!
                </div>
                <div
                  v-if="isSubmitted && !$v.itemForm.prix.decimal"
                  class="invalid-feedback"
                >
                  Entrez une valeur numérique !!
                </div>
              </div>

              <div class="form-group col">
                <label for="tva" class="form-control-sm mb-0">TVA :</label>
                <input
                  type="text"
                  v-model="itemForm.tva"
                  id="tva"
                  name="tva"
                  class="form-control form-control-sm"
                  :class="{
                    'is-invalid': isSubmitted && $v.itemForm.tva.$error,
                  }"
                />
                <div
                  v-if="isSubmitted && !$v.itemForm.tva.required"
                  class="invalid-feedback"
                >
                  Ce champ est obligatoire !!
                </div>
                <div
                  v-if="isSubmitted && !$v.itemForm.tva.decimal"
                  class="invalid-feedback"
                >
                  Entrez une valeur numérique !!
                </div>
              </div>
            </div>

            <div class="form-group">
              <textarea
                name="description"
                id="description"
                rows="2"
                placeholder="Description ..."
                class="form-control form-control-sm"
                v-model="itemForm.description"
              ></textarea>
            </div>

            <div class="form-group">
              <button type="submit" class="btn btn-secondary btn-block">
                Ajouter Article/Produit
              </button>
            </div>
          </form>
        </b-collapse>

        <div class="row mb-2">
          <h6
            class="col d-flex justify-content-center ml-3 font-weight-lighter"
          >
            Total HT: {{ total_price_ht }} DH
          </h6>
          <h6
            class="col d-flex justify-content-center ml-3 font-weight-lighter"
          >
            Total TVA: {{ total_tva }} DH
          </h6>
          <h6
            class="col d-flex justify-content-center ml-3 font-weight-lighter"
          >
            Total TTC: {{ total_price }} DH
          </h6>
        </div>

        <b-table
          sort-icon-left
          small
          responsive
          :fields="fieldsArticle"
          :items="ArticlesList"
        >
          <template #cell(actions)="row">
            <b-button
              pill
              size="sm"
              class="mr-2 float-right"
              variant="outline-danger"
              @click="del_click(row.item)"
            >
              <i class="fa fa-trash-alt"></i>
            </b-button>
          </template>
        </b-table>


      </div>
    </div>

    <!-- le modal -->
    <b-modal id="validerModal" title="Le paiement" hide-footer>
      <form @submit.prevent="handleSubmitReglerBn">
        <div class="mt-3 ml-2 text-secondary font-weight-light">
          Total Net (DH) :
          <span class="d-inline text-info"> {{ total_price }} </span>
        </div>

        <div class="form-group">
          <label for="bank_compte_id">Compte :</label>
          <select
            class="form-control"
            id="bank_compte_id"
            v-model="itemVal.bank_compte_id"
          >
            <option
              v-for="item in comptes"
              :key="item.id"
              v-bind:value="item.id"
            >
              {{ item.nom }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary btn-block mt-4">
            Valider
          </button>
        </div>
      </form>
    </b-modal>

    
    <!-- le modal -->
    <b-modal id="calculModal" title="Quantité"  hide-footer>
           <form @submit.prevent="handleSubmitCalc">
            <div class="form-group">
                <label for="name">Longueur </label>
                <input type="text" v-model="itemFormCal.long" id="long" name="long" class="form-control" />

            </div>

            <div class="form-group">
                <label for="name">Largeur </label>
                <input type="text" v-model="itemFormCal.larg" id="larg" name="larg" class="form-control" />

            </div>

            <div class="form-group">


            <div class="custom-control custom-switch mb-2">
            <input type="checkbox"
             class="custom-control-input" 
             id="etat"
                    v-model="itemFormCal.etat"
                    name="etat"
                    value=true
                    unchecked-value=false

             >

            <label class="custom-control-label" for="etat"> Hauteur </label>
            </div>

                <input type="text" v-if="itemFormCal.etat==true" v-model="itemFormCal.haut" id="haut" name="haut" class="form-control" />

            </div>

            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Calculer La Quantité</button>
            </div>


        </form>
    </b-modal>

    <!-- les trucs public -->

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#0179F9"
      loader="bars"
    ></loading>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { required, decimal } from "vuelidate/lib/validators";
import {
  ImageRun,
  HeadingLevel,
  PageNumber,
  PageNumberFormat,
  Footer,
  Header,
  Table,
  TableCell,
  TableRow,
  Document,
  Paragraph,
  WidthType,
  VerticalAlign,
  AlignmentType,
  Packer,
  TextRun,
} from "docx";
import { saveAs } from "file-saver";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
export default {
  props: [
    "idBon",
    "nomBon",
    "idFour",
    "nClient",
    "idDep",
    "nDep",
    "l_is_stock",
    "ice",
    "mode",
    "ddate"
  ],

  computed: {
        //3t pdf
        get_entete3t() {
      return this.$store.getters.get_entete3t;
    },  
    get_email_3t() {
      return this.$store.getters.get_email_3t;
    },
    get_contact_3t() {
      return this.$store.getters.get_contact_3t;
    },
    get_pied_pag_pdf3t1() {
      return this.$store.getters.get_pied_pag_pdf3t1;
    },
    get_pied_pag_pdf3t2() {
      return this.$store.getters.get_pied_pag_pdf3t2;
    },
    get_pied_pag_pdf3t3() {
      return this.$store.getters.get_pied_pag_pdf3t3;
    }, 
    //fin 3t pdf

    d_is_tresor() {
      return this.$store.getters.get_is_tresor;
    },
    d_is_print_pdf() {
      return this.$store.getters.get_is_print_pdf;
    },  
    d_is_logo() {
      return this.$store.getters.get_is_logo;
    },
             d_logo_w() {
      return this.$store.getters.get_logo_w;
    },
            d_logo_h() {
      return this.$store.getters.get_logo_h;
    },
    d_logo_img() {
      return this.$store.getters.get_logo_img;
    }, 
    d_img_logo_pdf() {
      return this.$store.getters.get_img_logo_pdf;
    }, 
    d_logo_w_pdf() {
      return this.$store.getters.get_logo_w_pdf;
    },
    d_logo_h_pdf() {
      return this.$store.getters.get_logo_h_pdf;
    },
    d_pied_pag_pdf1() {
      return this.$store.getters.get_pied_pag_pdf1;
    },
    d_pied_pag_pdf2() {
      return this.$store.getters.get_pied_pag_pdf2;
    },
    d_pied_pag_pdf3() {
      return this.$store.getters.get_pied_pag_pdf3;
    },

    d_is_entete() {
      return this.$store.getters.get_is_entete;
    },
             d_logo_e() {
      return this.$store.getters.get_logo_e;
    },
            d_logo_p() {
      return this.$store.getters.get_logo_p;
    },


    entet_pag() {
      if (this.$store.getters.get_entete) {
        return this.$store.getters.get_entete;
      } else {
        return "";
      }
    },
    sousentet_pag() {
      if (this.$store.getters.get_sousentete) {
        return this.$store.getters.get_sousentete;
      } else {
        return "";
      }
    },
    pied_pag() {
      if (this.$store.getters.get_pied) {
        return this.$store.getters.get_pied;
      } else {
        return "";
      }
    },
    user_name() {
      if (this.$store.getters.get_user) {
        return this.$store.getters.get_user.name;
      } else {
        return "";
      }
    },
    d_gst_fact_val() {
      return this.$store.getters.get_gst_fact_val;
    },
    filterArticle() {
      return this.listArt.filter((article) => {
        if (this.isCodeBarreSearch) {
          if (article.code_bar) {
            return article.code_bar
              .toLowerCase()
              .includes(this.serchArt.toLowerCase());
          }
        } else {
          return article.ref.toLowerCase().includes(this.serchArt.toLowerCase());
        }
      });
    },
    filterCategorie() {
      return this.listCat.filter((category) => {
        return category.nom
          .toLowerCase()
          .includes(this.searchCateg.toLowerCase());
      });
    },

    total_price: function () {
      return this.ArticlesList.reduce(
        (a, b) => +a + +(b.qte * b.prix),
        0
      ).toFixed(2);
    },
    total_price_ht: function () {
      return this.ArticlesList.reduce(
        (a, b) => +a + +(b.qte * b.prix_ht),
        0
      ).toFixed(2);
    },
    total_tva: function () {
      return (this.total_price - this.total_price_ht).toFixed(2);
    },
  },
  mounted() {
    if (this.idBon) {
      this.fetchCategorieList();
      this.fetchListArts(this.idBon);
    } else {
      this.flashMessage.show({
        status: "error",
        title: "Erreur de Chargement",
        message: "Impossible charger les données de cette facture",
      });
    }
  },
  data() {
    return {


            itemFormCal: {
                    long:1,
                    larg:1,
                    haut:1,
                    etat:false},

      comptes: [],
      itemVal: {
        bank_compte_id: "",
        date: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
      },
      fieldsArticle: [
        { key: "ref", label: "Réf" },
        { key: "qte", label: "Qté" },
        { key: "unite", label: "Unité" },
        { key: "prix_ht", label: "Prix HT" },
        { key: "prix", label: "Prix TTC" },
        { key: "nom_lot", label: "Lot" },

        { key: "actions", label: "" },
      ],

      isCodeBarreSearch: false,
      searchCateg: "",
      serchArt: "",
      ArticlesList: [],
      listCat: [],
      listArt: [],
      itemForm: {
        id: "",
        ref: "",
        code_bar: "",
        description: "",
        qte: "",
        prix: "",
        tva: "",
        unite: "",
        nom_lot: "",
        lot_id: "",
        article_id: "",
        is_lot_manag: "",
      },
      itemFormLot: {
        nom: "",
        qte: "",
        date: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
        date_exp: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
        depot_id: "",
        article_id: "",
      },
      ListLots: [],
      isSubmitted: false,
      isLoading: false,

    };
  },
  validations: {
    itemForm: {
      ref: {
        required,
      },
      qte: {
        required,
        decimal,
      },
      article_id: {
        required,
      },
      prix: {
        required,
        decimal,
      },
      tva: {
        required,
        decimal,
      },
      nom_lot: {
        required,
      },
      lot_id: {
        required,
      },
    },
  },
  methods: {
    add_calc_click(button) {
        this.itemFormCal.long=1
        this.itemFormCal.larg=1
        this.itemFormCal.haut=1

        this.itemFormCal.etat=false

        this.$root.$emit('bv::show::modal', 'calculModal', button)
    },
    handleSubmitCalc()
    {
        if( this.itemFormCal.etat==false)
        {
            this.itemFormCal.haut=1
        }

        if(isNaN(this.itemFormCal.long) || isNaN(this.itemFormCal.larg) ||isNaN(this.itemFormCal.haut))
        {
                this.flashMessage.show({
                status: 'info',
                title: 'Entrez des valeurs numériques et Reésayez !!',
                })
        }
        else
        {
                this.itemForm.qte = (this.itemFormCal.long * this.itemFormCal.larg * this.itemFormCal.haut);

                        if( this.itemForm.description==null)
                            {
                                this.itemForm.description=""
                            }

                        if( this.itemFormCal.etat==false)
                            {
                                this.itemForm.description= this.itemForm.description +"(Long :"+this.itemFormCal.long+" / Larg :"+this.itemFormCal.larg+" )"
                            }
                            else
                            {
                                this.itemForm.description= this.itemForm.description +"(Long :"+this.itemFormCal.long+" / Larg :"+this.itemFormCal.larg+" / Haut :"+this.itemFormCal.haut+" )"
                                
                            }

                this.$bvModal.hide('calculModal')
        }



    },


    fetchFormComptes_regler: function () {
      //comptes :

      if (this.l_is_stock == 0 && !this.d_is_tresor==false) {
        this.isLoading = true;
        this.$http
          .get("/bankComptes")
          .then((result) => {
            this.comptes = result.data.data;
            this.isLoading = false;

            this.$root.$emit("bv::show::modal", "validerModal");
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
            this.flashMessage.show({
              status: "error",
              title: "Erreur de Chargement",
              message:
                "Impossible charger les données.. Actualisez et Réessayez",
            });
          });



          
      } else {
        this.$confirm("Voulez-vous vraiment VALIDER Et REGLER cette Facture??")
          .then(() => {
            this.validerBon(1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },



    handleSubmitReglerBn() {
      if (!this.itemVal.bank_compte_id) {
        this.flashMessage.show({
          status: "info",
          title: "Sélectionnez un compte !!",
        });
      } else {
        //procedure du modification
        this.isLoading = true;

        this.$http
          .post("/validFactureClRegl", {
            date: this.itemVal.date,
            user: this.user_name,
            bank_compte_id: this.itemVal.bank_compte_id,
            id_bon: this.idBon,
            type: 1,
            regl: 1,
          })
          .then((response) => {
            this.isLoading = false;
            console.log(response.data);
            this.$bvModal.hide("validerModal");

            //goto first page:
            this.$router.push({
              name: "factureClient",
            });

            this.flashMessage.show({
              status: "success",
              title: "Données modifiées",
              message: "Les données sont modifiées correctement",
            });
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;

            this.flashMessage.show({
              status: "error",
              title: "Erreur De Modification",
              message: "Impossible  de modifier les données .. Réessayez !!",
            });
          });
      }
    },

    clic_export()
    {
        if(this.d_is_logo == true)
        {
        this.exportDoxLogo()
        }
        else
        {
        this.exportDox()
        }
    },

    export_pdf()
    {

          var imgData=this.d_img_logo_pdf;

          var imgData_w=this.d_logo_w_pdf;
          var imgData_h=this.d_logo_h_pdf;


    //3t pdf
    var local_entet_pag = this.get_entete3t
      var local_get_email_3t = this.get_email_3t
      var local_get_contact_3t = this.get_contact_3t


      var piedPg_local= this.get_pied_pag_pdf3t1;
      var piedPg_local2= this.get_pied_pag_pdf3t2;
      var piedPg_local3= this.get_pied_pag_pdf3t3
    //fin 3t pdf 



          let total_entier = parseInt(this.total_price);
          let total_decimal = Math.round((this.total_price- parseInt(this.total_price))*100);


          let total_lettre_entier ="";

          if(total_decimal>0)
          {
          total_lettre_entier = this.NumberToLetter(total_entier) + " Dirhams . " +this.NumberToLetter(total_decimal)+ " Centimes. " ;
          }
          else
          {
          total_lettre_entier = this.NumberToLetter(total_entier) + " Dirhams.";
          }



          var totalht_local = this.total_price_ht.toString() + " DH";

          var totaltva_local = this.total_tva.toString() + " DH";
          var totalttc_local = this.total_price.toString() + " DH";

          var les_totaux="Total HT : "+totalht_local+"\nTotal T.V.A : "+totaltva_local+"\nTotal TTC : "+totalttc_local


          var n_local = this.nomBon;
          var date_local = this.ddate;

          var client_local = this.nClient;
          var ice_local = this.ice;
          var mode_local = this.mode;
          
          if(ice_local==null)
          {
            ice_local=""
          }
          const doc = new jsPDF();



          autoTable(doc, {
            body: [
                [
                {
                    content: '',
                    styles: {
                    halign: 'left'
                    }
                },
                {
                    content: '',
                    styles: {
                    halign: 'right'
                    }
                }
                ],
            ],

                didDrawCell: function (data) {

            if (data.section === 'body' && data.column.dataKey === 0) {


                    doc.addImage(imgData,'JPEG',data.cell.x,data.cell.y,imgData_w,imgData_h);
                    

            }


            if (data.section === 'body' && data.column.dataKey === 1) {
                doc.autoTable({
                startY: data.cell.y + 0.7,
                margin: { left: data.cell.x },
                tableWidth: data.cell.width - 2,
                styles: {
                    maxCellHeight: 5,
                },

                body: [
                    [
                        {
                        content: local_entet_pag,
                        styles: {
                            halign: 'right',
                            fontSize : 11,
                            textColor : '#000000',
                        
                            lineColor:'#89663c', 
                            lineWidth:{top: 0, right: 1.5, bottom: 0, left: 0},
                            cellPadding: {top: 0, right: 3, bottom: 0, left: 0},

                        },
                        
                        },


                    ]
            ],
            theme: 'grid'
                })
            

                                        
            }





            },



            theme: 'plain',
            styles: { minCellHeight: 18 },
            margin: {bottom: 0,left:7 ,right:7 ,top:9},
            columnStyles: {
                0: {
                    cellWidth: 80
                },
                1: {
                    cellWidth: 'auto'
                }}



            });


        //table nom bon 
        autoTable(doc, {
              body: [
                [
                  {
                    styles: {                   
                    lineColor:'#89663c', 
                    minCellHeight:10,
                    lineWidth:{top: 0, right: 1.5, bottom: 0, left: 0},
                    cellPadding: {top: 0, right: 3, bottom: 0, left: 0},

                  },
                  },
                
                ],
              ],

                        didDrawCell: (data) => {
                            if (data.section === 'body' && data.column.dataKey === 0) {
                               doc.setFillColor(22, 54, 92);
                               doc.setDrawColor(22, 54, 92);
                               doc.roundedRect( data.cell.x , data.cell.y, data.cell.width-3, data.cell.height, 0, 3, 'FD');
                                  doc.setFontSize(16)
                                  doc.setTextColor(255,255,255)
                                 doc.text('F A C T U R E', data.cell.x + 18, data.cell.y+7)

         

                            }


                        },


cellPadding:{left: 6 },
    margin: {left: 125,right:9 },

    startY: 39,

      theme: 'grid',

    });

        //ligne
            doc.setDrawColor(22,54,92);
          doc.setLineWidth(0.5);
          doc.line(7, 52, 202, 52);

        //client

        autoTable(doc, {
          body: [
            [
              {
                styles: {
                  halign: 'right'
                }
              }
            ],
          ],

        didDrawCell: function (data) {
          if (data.section === 'body' && data.column.dataKey === 0) {
            doc.autoTable({

              startY: data.cell.y + 2,
              margin: { left: data.cell.x + 2 },
              tableWidth: data.cell.width - 4,
              styles: {
                maxCellHeight: 4,
                minCellWidth: 30
              },

            body: [
                  [
                    {
                      content: 'Client : ',
                      styles: {
                        halign: 'left',
                        fontStyle:'bold',
                        fontSize : 11,
                        textColor : '#2f64a4',
                      lineWidth:{top: 0, right: 0, bottom: 0, left: 0}
                      }
                    },

                    {
                      content: client_local,
                      styles: {
                        halign: 'left',
                        fontSize : 11,
                        textColor : '#000000',
                        lineColor:'#16365c', 
                      lineWidth:{top: 0, right: 0, bottom: 0, left: 0}

                      }
                    }
                  ],

                  [
                    {
                      content: 'ICE : ',
                      styles: {
                        halign: 'left',
                        fontStyle:'bold',
                        fontSize : 11,
                        textColor : '#2f64a4',
                        lineColor:'#16365c', 
                        lineWidth:{top: 0, right: 0, bottom: 0, left: 0}
                      }
                    },

                    {
                      content: ice_local+' ',
                      styles: {
                        halign: 'left',
                        fontSize : 11,
                        textColor : '#000000',
                        lineColor:'#16365c', 
                        lineWidth:{top: 0, right: 0, bottom: 0, left: 0},
                      }
                    }
                  ],

          ],

          theme: 'grid',

            })
          
                                    
          }





        },



          theme: 'plain',
          styles: { minCellHeight: 30.5 },

            margin: { right: 120 },
            startY: 56,


        });

        //data et num

        autoTable(doc, {
          body: [
            [
              {
                styles: {
                  halign: 'right'
                }
              }
            ],
          ],

        didDrawCell: function (data) {
          if (data.section === 'body' && data.column.dataKey === 0) {
            doc.autoTable({

              startY: data.cell.y + 2,
              margin: { left: data.cell.x + 2 },
              tableWidth: data.cell.width - 4,
              styles: {
                maxCellHeight: 4,
                minCellWidth: 30
              },

            body: [
                  [
                    {
                      content: 'Date : ',
                      styles: {
                        halign: 'left',
                        fontStyle:'bold',
                        fontSize : 11,
                        textColor : '#2f64a4',
                      lineWidth:{top: 0, right: 0, bottom: 0, left: 0}
                      }
                    },

                    {
                      content: date_local,
                      styles: {
                        halign: 'left',
                        fontSize : 11,
                        textColor : '#000000',
                      lineWidth:{top: 0, right: 0, bottom: 0, left: 0}

                      }
                    }
                  ],

                  [
                    {
                      content: 'Bon N° : ',
                      styles: {
                        halign: 'left',
                        fontStyle:'bold',
                        fontSize : 11,
                        textColor : '#2f64a4',
                        lineWidth:{top: 0, right: 0, bottom: 0, left: 0}
                      }
                    },

                    {
                      content: n_local+' ',
                      styles: {
                        halign: 'left',
                        fontSize : 11,
                        textColor : '#000000',
                        lineWidth:{top: 0, right: 0, bottom: 0, left: 0}
                      }
                    }
                  ],

          ],

          theme: 'grid',

            })
          
                                    
          }





        },



          theme: 'plain',
          styles: { minCellHeight: 30.5 },

            margin: { left: 107 },
            startY: 56,


        });





          let article_body=[]

          this.ArticlesList.forEach(function (item) {


            article_body.push([item.ref, item.description, Number(item.tva).toFixed(0).toString() + "%", item.qte.toString(), item.prix_ht.toString(), item.total_ht.toString()]);
          });


          

      autoTable(doc, {
      head: [['Référence', 'Description', 'TVA', 'Quantité', 'P.U.H.T', 'Total HT']],
      body: article_body,
      foot: [[{"content":"","colSpan":3},{"content":les_totaux,"colSpan":3}],
      [{"content":"-Arrêtée la présente facture à la somme de : "+total_lettre_entier,"colSpan":6}],
      [{"content":"-Conditions de règlement : "+mode_local,"colSpan":6}]],
      
      textColor : '#000000',
      startY:80,
      //margin: {bottom: 40 },
      margin: {bottom: 45,left:8 ,right:8 ,top:8},
      didDrawPage: function (data) {

            // Footer
            //  var str = 'P:' + doc.internal.getNumberOfPages()

          doc.setFontSize(11)

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize
          var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()




    //foter

      autoTable(doc, {
      body: [
        [
          {
            styles: {
              halign: 'right'
            },
           
          },
          {
            styles: {
              halign: 'right'
            },
           
          },
          {
            styles: {
              halign: 'right'
            },
           
          }
        ],
      ],

    didDrawCell: function (data) {
      if (data.section === 'body' && data.column.dataKey === 0) {
        doc.autoTable({

          startY: data.cell.y + 2,
          margin: { left: data.cell.x + 2 },
          tableWidth: data.cell.width - 4,
          styles: {
            maxCellHeight: 4,
            minCellWidth: 30
          },



        body: [
              [
                {
                  content: piedPg_local,
                  styles: {
                    halign: 'left',
                    fontSize : 9,
                    textColor : '#000000',
                  lineWidth:{top: 0, right: 0, bottom: 0, left: 0}

                  }
                }
              ],

      ],

       theme: 'grid',

        })
      
                                 
      }
      if (data.section === 'body' && data.column.dataKey === 1) {
        doc.autoTable({

          startY: data.cell.y + 2,
          margin: { left: data.cell.x + 2 },
          tableWidth: data.cell.width - 4,
          styles: {
            maxCellHeight: 4,
            minCellWidth: 30
          },

        body: [
              [
                {
                  content: piedPg_local2,
                  styles: {
                    halign: 'left',
                    fontSize : 9,
                    textColor : '#000000',
                  lineWidth:{top: 0, right: 0, bottom: 0, left: 0}

                  }
                }
              ],

      ],

       theme: 'grid',

        })
      
                                 
      }

      if (data.section === 'body' && data.column.dataKey === 2) {
        doc.autoTable({

          startY: data.cell.y + 2,
          margin: { left: data.cell.x + 2 },
          tableWidth: data.cell.width - 4,
          styles: {
            maxCellHeight: 4,
            minCellWidth: 30
          },

        body: [
              [
                {
                  content: piedPg_local3,
                  styles: {
                    halign: 'left',
                    fontSize : 9,
                    textColor : '#000000',
                  lineWidth:{top: 0, right: 0, bottom: 0, left: 0}

                  }
                }
              ],

      ],

       theme: 'grid',

        })
      
                                 
      }
    },



      theme: 'plain',
       styles: { minCellHeight: 10.5 },
       margin: {bottom: 0,left:8 ,right:8 ,top:0},
      //  margin: { left: 107 },
        startY: pageHeight - 43
        ,


    });


        doc.setTextColor(72,82,220)
          doc.text(local_get_email_3t, data.settings.margin.left+8, pageHeight - 10)
          doc.text(local_get_contact_3t, 170, pageHeight - 10)

          doc.setDrawColor(22,54,92);
          doc.setLineWidth(0.5);
          doc.line(10, pageHeight - 15, 200, pageHeight - 15);




    },

 
      theme: 'striped',
      headStyles:{
        textColor : '#FFFFFF',
        fontSize : 12,
        fillColor: '#16365c'
      },
      footStyles:{
        textColor : '#000000',
        fontSize : 12,
        fillColor: '#9ac2f2',
      cellPadding:{bottom: 3,left:4 ,right:0 ,top:3},
     // minCellHeight:15
      },
      showFoot: "lastPage"

    });





        doc.save('Facture '+n_local+'_'+client_local+'.pdf');




    },
    //fin export pdf





    exportDoxEntet() {

    let total_entier = parseInt(this.total_price);
    let total_decimal = Math.round((this.total_price- parseInt(this.total_price))*100);


    let total_lettre_entier ="";

    if(total_decimal>0)
    {
    total_lettre_entier = this.NumberToLetter(total_entier) + " Dirhams . " +this.NumberToLetter(total_decimal)+ " Centimes. " ;
    }
    else
    {
    total_lettre_entier = this.NumberToLetter(total_entier) + " Dirhams.";
    }

          let datrw = [
            new TableRow({
              children: [
                new TableCell({
                  width: {
                    size: 3000,
                    type: WidthType.DXA,
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      children: [
                                                new TextRun({
                                                text: "Référence",
                                                color: '0f31b5',
                                                bold: false,
                                                font: "Segoe UI"
                                                }),
                                            ],
                      spacing: {
                        before: 100,
                        after: 100,
                      },
                      heading: HeadingLevel.HEADING_3,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),

                new TableCell({
                  width: {
                    size: 6000,
                    type: WidthType.DXA,
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      children: [
                                                new TextRun({
                                                text: "Description",
                                                color: '0f31b5',
                                                bold: false,
                                                font: "Segoe UI"
                                                }),
                                            ],
                      heading: HeadingLevel.HEADING_3,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),


                new TableCell({
                  width: {
                    size: 1000,
                    type: WidthType.DXA,
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      children: [
                                                new TextRun({
                                                text: "TVA",
                                                color: '0f31b5',
                                                bold: false,
                                                font: "Segoe UI"
                                                }),
                                            ],
                      heading: HeadingLevel.HEADING_3,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),

                new TableCell({
                  width: {
                    size: 2000,
                    type: WidthType.DXA,
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      children: [
                                                new TextRun({
                                                text: "Quantité",
                                                color: '0f31b5',
                                                bold: false,
                                                font: "Segoe UI"
                                                }),
                                            ],
                      heading: HeadingLevel.HEADING_3,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),

                new TableCell({
                  width: {
                    size: 2500,
                    type: WidthType.DXA,
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      children: [
                                                new TextRun({
                                                text: "P.U.H.T",
                                                color: '0f31b5',
                                                bold: false,
                                                font: "Segoe UI"
                                                }),
                                            ],
                      heading: HeadingLevel.HEADING_3,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),

                new TableCell({
                  width: {
                    size: 2500,
                    type: WidthType.DXA,
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      children: [
                      new TextRun({
                                                text: "Total HT",
                                                color: '0f31b5',
                                                bold: false,
                                                font: "Segoe UI"
                                                }),
                                            ],
                      heading: HeadingLevel.HEADING_3,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),
              ],
            }),
          ];

          this.ArticlesList.forEach(function (item) {
            let r = new TableRow({
              children: [
                new TableCell({
                  width: {
                    size: 3000,
                    type: WidthType.DXA,
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      children: [
                                            new TextRun({
                                                text: item.ref,
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],
                      spacing: {
                        before: 100,
                        after: 100,
                        left: 10,
                        right: 10,
                      },
                      heading: HeadingLevel.HEADING_3,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),

                new TableCell({
                  width: {
                    size: 6000,
                    type: WidthType.DXA,
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      children: [
                                            new TextRun({
                                                text: item.description,
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],
                      spacing: {
                        before: 10,
                        after: 10,
                        left: 10,
                        right: 10,
                      },
                      heading: HeadingLevel.HEADING_3,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),



                new TableCell({
                  width: {
                    size: 1000,
                    type: WidthType.DXA,
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                                        
                      children: [
                                            new TextRun({
                                              text: Number(item.tva).toFixed(0).toString() + "%",
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],
                      spacing: {
                        before: 10,
                        after: 10,
                        left: 10,
                        right: 10,
                      },
                      heading: HeadingLevel.HEADING_3,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),

                new TableCell({
                  width: {
                    size: 2000,
                    type: WidthType.DXA,
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({

                      children: [
                                                new TextRun({
                                                text: item.qte.toString(),
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],

                      heading: HeadingLevel.HEADING_3,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),
                new TableCell({
                  width: {
                    size: 2500,
                    type: WidthType.DXA,
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      children: [
                                            new TextRun({
                                              text: item.prix_ht.toString(),
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],
                      heading: HeadingLevel.HEADING_3,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),

                new TableCell({
                  width: {
                    size: 2500,
                    type: WidthType.DXA,
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      children: [
                                            new TextRun({
                                              text: Number(item.total_ht).toFixed(2).toString(),
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],
                      heading: HeadingLevel.HEADING_3,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),
              ],
            });

            datrw.push(r);
          });

          const table = new Table({
            rows: datrw,
          });

          //pied

          let datrw_salare_net = [
            new TableRow({
              children: [
                new TableCell({
                  width: {
                    size: 6250,
                    type: WidthType.DXA,
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
      
                      children: [
                                                new TextRun({
                                                  text: "Total HT",
                                                color: '000000',
                                                bold: false,
                                                font: "Segoe UI"
                                                }),
                                            ],
                      spacing: {
                        before: 80,
                        after: 80,
                      },
                      heading: HeadingLevel.HEADING_3,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),

                new TableCell({
                  width: {
                    size: 6250,
                    type: WidthType.DXA,
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      
                      
                      children: [
                                                new TextRun({
                                                text: this.total_price_ht.toString() + " DH",
                                                color: '000000',
                                                bold: false,
                                                font: "Segoe UI"
                                                }),
                                            ],
                      heading: HeadingLevel.HEADING_3,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),
              ],
            }),

            new TableRow({
              children: [
                new TableCell({
                  width: {
                    size: 6250,
                    type: WidthType.DXA,
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                                      
                      children: [
                                                new TextRun({
                                                text: "Total TVA",
                                                color: '000000',
                                                bold: false,
                                                font: "Segoe UI"
                                                }),
                                            ],
                      spacing: {
                        before: 80,
                        after: 80,
                      },
                      heading: HeadingLevel.HEADING_3,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),

                new TableCell({
                  width: {
                    size: 6250,
                    type: WidthType.DXA,
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      
                      
                      children: [
                                                new TextRun({
                                                  text: this.total_tva.toString() + " DH",
                                                color: '000000',
                                                bold: false,
                                                font: "Segoe UI"
                                                }),
                                            ],
                      heading: HeadingLevel.HEADING_3,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),
              ],
            }),

            new TableRow({
              children: [
                new TableCell({
                  width: {
                    size: 6250,
                    type: WidthType.DXA,
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      
                      children: [
                                                new TextRun({
                                                text: "Total TTC",
                                                color: '000000',
                                                bold: false,
                                                font: "Segoe UI"
                                                }),
                                            ],
                      spacing: {
                        before: 80,
                        after: 80,
                      },
                      heading: HeadingLevel.HEADING_3,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),

                new TableCell({
                  width: {
                    size: 6250,
                    type: WidthType.DXA,
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                                        
                      children: [
                                                new TextRun({
                                                  text: this.total_price.toString() + " DH",
                                                color: '000000',
                                                bold: false,
                                                font: "Segoe UI"
                                                }),
                                            ],
                      heading: HeadingLevel.HEADING_3,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),
              ],
            }),
          ];

          const tablenet = new Table({
            rows: datrw_salare_net,
          });

          let datrw_arret= [
            new TableRow({
              children: [
                new TableCell({
                  width: {
                    size: 6250,
                    type: WidthType.DXA,
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text:
                                        "  Arrêtée la présente facture à la somme de : ",
                                      color: "000000",
                                      bold: true,
                                      font: "Segoe UI",
                                    }),
                                  ],

                                  heading: HeadingLevel.HEADING_4,
                                  alignment: AlignmentType.LEFT,

                                  spacing: {
                                    after: 100,
                                    before: 100,
                                  },
                                }),



                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text:" "+total_lettre_entier,
                                      color: "000000",
                                      bold: false,
                                      font: "Segoe UI",
                                    }),
                                  ],

                                  heading: HeadingLevel.HEADING_5,
                                  alignment: AlignmentType.LEFT,

                                  spacing: {
                                    after: 100,
                                    before: 100,
                                  },
                                }),
                  ],
                }),

                new TableCell({
                  width: {
                    size: 6250,
                    type: WidthType.DXA,
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text:
                                        " Conditions de règlement : ",
                                      color: "000000",
                                      bold: true,
                                      font: "Segoe UI",
                                    }),
                                  ],

                                  heading: HeadingLevel.HEADING_4,
                                  alignment: AlignmentType.LEFT,

                                  spacing: {
                                    after: 100,
                                    before: 100,
                                  },
                                }),



                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text:"  "+this.mode,
                                      color: "000000",
                                      bold: false,
                                      font: "Segoe UI",
                                    }),
                                  ],

                                  heading: HeadingLevel.HEADING_5,
                                  alignment: AlignmentType.LEFT,

                                  spacing: {
                                    after: 100,
                                    before: 100,
                                  },
                                }),

                  ],
                }),
              ],
            }),

          ];

          const tablearret = new Table({
            rows: datrw_arret,
          });

          //fin pie


    const doc = new Document({

        sections: [
            {

            properties: {
              page: {
                pageNumbers: {
                  start: 1,
                  formatType: PageNumberFormat.DECIMAL,
                },
                margin:{
                                    left:500,
                                    right:500
                                }
              },
            },
                        headers: {

                            default: new Header({
                                children: [

                                new Paragraph({
                                        children: [
                                        ],

                                        heading: HeadingLevel.HEADING_5,
                                        alignment: AlignmentType.CENTER,
                                        spacing: {
                                                        after: this.d_logo_e,
                                                        before: 200
                                                    },
                                    }),



                                ],
                            }),
                        },
                        footers: {
                            default: new Footer({
                                children: [

                            new Paragraph({
                                        children: [

                                        ],

                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                        spacing: {
                                                        after: this.d_logo_p,
                                                        before: 200
                                                    },
                                    }),

                                ],
                            }),
                        },


                        children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Facture N° : "+this.nomBon,
                    color: "000000",
                    bold: false,
                    font: "Segoe UI",
                  }),
                ],

                heading: HeadingLevel.HEADING_1,
                alignment: AlignmentType.CENTER,

                spacing: {
                  after: 200,
                  before: 200,
                },
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: "Client : ",
                    color: "000000",
                    bold: true,
                    font: "Segoe UI",
                  }),
                  new TextRun({
                    text: this.nClient,
                    color: "000000",
                    bold: false,
                    font: "Segoe UI",
                  }),
                  new TextRun({
                    text: "          ICE : ",
                    color: "000000",
                    bold: true,
                    font: "Segoe UI",
                  }),

                  new TextRun({
                    text: this.ice,
                    color: "000000",
                    bold: false,
                    font: "Segoe UI",
                  }),

                                new TextRun({
                    text: "          Date : ",
                    color: "000000",
                    bold: true,
                    font: "Segoe UI",
                  }),

                  new TextRun({
                    text: this.ddate,
                    color: "000000",
                    bold: false,
                    font: "Segoe UI",
                  }),
                ],



                heading: HeadingLevel.HEADING_3,
                alignment: AlignmentType.CENTER,

                spacing: {
                  after: 300,
                  before: 100,
                },
              }),

              table,

              new Paragraph({
                children: [
                  new TextRun({
                    text: "",
                    color: "000000",
                    bold: false,
                    font: "Segoe UI",
                  }),
                ],

                heading: HeadingLevel.HEADING_2,
                alignment: AlignmentType.CENTER,

                spacing: {
                  after: 50,
                  before: 50,
                },
              }),

              tablenet,
              new Paragraph({
                children: [
                  new TextRun({
                    text: "",
                    color: "000000",
                    bold: false,
                    font: "Segoe UI",
                  }),
                ],

                heading: HeadingLevel.HEADING_2,
                alignment: AlignmentType.CENTER,

                spacing: {
                  after: 50,
                  before: 50,
                },
              }),
    tablearret


            ],

            }]

    });



          Packer.toBlob(doc).then((blob) => {
            saveAs(blob, this.nomBon + ".docx");
          });
    },

    exportDoxLogo() {

let total_entier = parseInt(this.total_price);
let total_decimal = Math.round((this.total_price- parseInt(this.total_price))*100);


let total_lettre_entier ="";

if(total_decimal>0)
{
 total_lettre_entier = this.NumberToLetter(total_entier) + " Dirhams . " +this.NumberToLetter(total_decimal)+ " Centimes. " ;
}
else
{
 total_lettre_entier = this.NumberToLetter(total_entier) + " Dirhams.";
}



let datrw = [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 3000,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: [
                                            new TextRun({
                                            text: "Référence",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  spacing: {
                    before: 100,
                    after: 100,
                  },
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),

            new TableCell({
              width: {
                size: 6000,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: [
                                            new TextRun({
                                            text: "Description",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),


            new TableCell({
              width: {
                size: 1000,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: [
                                            new TextRun({
                                            text: "TVA",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),

            new TableCell({
              width: {
                size: 2000,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: [
                                            new TextRun({
                                            text: "Quantité",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),

            new TableCell({
              width: {
                size: 2500,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: [
                                            new TextRun({
                                            text: "P.U.H.T",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),

            new TableCell({
              width: {
                size: 2500,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: [
                  new TextRun({
                                            text: "Total HT",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
          ],
        }),
      ];

      this.ArticlesList.forEach(function (item) {
        let r = new TableRow({
          children: [
            new TableCell({
              width: {
                size: 3000,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: [
                                        new TextRun({
                                            text: item.ref,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                  spacing: {
                    before: 100,
                    after: 100,
                    left: 10,
                    right: 10,
                  },
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),

            new TableCell({
              width: {
                size: 6000,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: [
                                        new TextRun({
                                            text: item.description,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                  spacing: {
                    before: 10,
                    after: 10,
                    left: 10,
                    right: 10,
                  },
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),



            new TableCell({
              width: {
                size: 1000,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                                    
                  children: [
                                        new TextRun({
                                          text: Number(item.tva).toFixed(0).toString() + "%",
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                  spacing: {
                    before: 10,
                    after: 10,
                    left: 10,
                    right: 10,
                  },
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),

            new TableCell({
              width: {
                size: 2000,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({

                  children: [
                                            new TextRun({
                                            text: item.qte.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],

                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
            new TableCell({
              width: {
                size: 2500,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: [
                                        new TextRun({
                                          text: item.prix_ht.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),

            new TableCell({
              width: {
                size: 2500,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: [
                                        new TextRun({
                                          text: Number(item.total_ht).toFixed(2).toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
          ],
        });

        datrw.push(r);
      });

      const table = new Table({
        rows: datrw,
      });

      //pied

      let datrw_salare_net = [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
  
                  children: [
                                            new TextRun({
                                              text: "Total HT",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  spacing: {
                    before: 80,
                    after: 80,
                  },
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),

            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  
                  
                  children: [
                                            new TextRun({
                                            text: this.total_price_ht.toString() + " DH",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
          ],
        }),

        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                                   
                  children: [
                                            new TextRun({
                                            text: "Total TVA",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  spacing: {
                    before: 80,
                    after: 80,
                  },
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),

            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  
                  
                  children: [
                                            new TextRun({
                                              text: this.total_tva.toString() + " DH",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
          ],
        }),

        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  
                  children: [
                                            new TextRun({
                                            text: "Total TTC",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  spacing: {
                    before: 80,
                    after: 80,
                  },
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),

            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                                    
                  children: [
                                            new TextRun({
                                              text: this.total_price.toString() + " DH",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
          ],
        }),
      ];

      const tablenet = new Table({
        rows: datrw_salare_net,
      });

      //fin pie

            //arret

      let datrw_arret= [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:
                                    "  Arrêtée la présente facture à la somme de : ",
                                  color: "000000",
                                  bold: true,
                                  font: "Segoe UI",
                                }),
                              ],

                              heading: HeadingLevel.HEADING_4,
                              alignment: AlignmentType.LEFT,

                              spacing: {
                                after: 100,
                                before: 100,
                              },
                            }),



                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:" "+total_lettre_entier,
                                  color: "000000",
                                  bold: false,
                                  font: "Segoe UI",
                                }),
                              ],

                              heading: HeadingLevel.HEADING_5,
                              alignment: AlignmentType.LEFT,

                              spacing: {
                                after: 100,
                                before: 100,
                              },
                            }),
              ],
            }),

            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:
                                    " Conditions de règlement : ",
                                  color: "000000",
                                  bold: true,
                                  font: "Segoe UI",
                                }),
                              ],

                              heading: HeadingLevel.HEADING_4,
                              alignment: AlignmentType.LEFT,

                              spacing: {
                                after: 100,
                                before: 100,
                              },
                            }),



                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:"  "+this.mode,
                                  color: "000000",
                                  bold: false,
                                  font: "Segoe UI",
                                }),
                              ],

                              heading: HeadingLevel.HEADING_5,
                              alignment: AlignmentType.LEFT,

                              spacing: {
                                after: 100,
                                before: 100,
                              },
                            }),

              ],
            }),
          ],
        }),

      ];

      const tablearret = new Table({
        rows: datrw_arret,
      });

      //fin pie


const doc = new Document({

    sections: [
        {

        properties: {
          page: {
            pageNumbers: {
              start: 1,
              formatType: PageNumberFormat.DECIMAL,
            },
            margin:{
                                left:500,
                                right:500
                            }
          },
        },
                    headers: {

                        default: new Header({
                            children: [




                    new Paragraph({
                    children: [


                        new ImageRun({
                            
                        data: Uint8Array.from(atob(this.d_logo_img), c =>
                            c.charCodeAt(0)
                        ),
                        transformation: {
                            width: this.d_logo_w,
                            height: this.d_logo_h
                        },



                        })
                    ]
                    }),

                            /* new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.sousentet_pag,
                                color: '000000',
                                bold: false,
                                })

                                    ],
                                border: {
                                bottom: {
                                        color: "000000",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_3,
                                    alignment: AlignmentType.CENTER,
                                }),*/

                            new Paragraph({
                                    children: [
                                    ],
                                border: {
                                bottom: {
                                        color: "000000",
                                        space: 1,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_5,
                                    alignment: AlignmentType.CENTER,
                                }),



                            ],
                        }),
                    },
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: this.pied_pag,
                    color: "000000",
                    bold: false,
                  }),
                ],
                border: {
                  top: {
                    color: "000000",
                    space: 4,
                    value: "single",
                    size: 10,
                  },
                },
                heading: HeadingLevel.HEADING_3,
                alignment: AlignmentType.CENTER,
              }),

              new Paragraph({
                alignment: AlignmentType.RIGHT,
                children: [
                  new TextRun({
                    children: ["Page : ", PageNumber.CURRENT],
                    color: "000000",
                  }),
                  new TextRun({
                    children: [" Sur ", PageNumber.TOTAL_PAGES],
                    color: "000000",
                  }),
                ],
              }),
            ],
          }),
        },


        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "Facture N° : "+this.nomBon,
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
            ],

            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.CENTER,

            spacing: {
              after: 200,
              before: 200,
            },
          }),

          new Paragraph({
            children: [
              new TextRun({
                text: "Client : ",
                color: "000000",
                bold: true,
                font: "Segoe UI",
              }),
              new TextRun({
                text: this.nClient,
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
              new TextRun({
                text: "          ICE : ",
                color: "000000",
                bold: true,
                font: "Segoe UI",
              }),

              new TextRun({
                text: this.ice,
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),

                            new TextRun({
                text: "          Date : ",
                color: "000000",
                bold: true,
                font: "Segoe UI",
              }),

              new TextRun({
                text: this.ddate,
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
            ],



            heading: HeadingLevel.HEADING_3,
            alignment: AlignmentType.CENTER,

            spacing: {
              after: 300,
              before: 100,
            },
          }),

          table,

          new Paragraph({
            children: [
              new TextRun({
                text: "",
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
            ],

            heading: HeadingLevel.HEADING_2,
            alignment: AlignmentType.CENTER,

            spacing: {
              after: 50,
              before: 50,
            },
          }),

          tablenet,
          new Paragraph({
            children: [
              new TextRun({
                text: "",
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
            ],

            heading: HeadingLevel.HEADING_2,
            alignment: AlignmentType.CENTER,

            spacing: {
              after: 50,
              before: 50,
            },
          }),
tablearret


        ],

        }]

});



      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, this.nomBon + ".docx");
      });
    },

    exportDox() {

let total_entier = parseInt(this.total_price);
let total_decimal = Math.round((this.total_price- parseInt(this.total_price))*100);


let total_lettre_entier ="";

if(total_decimal>0)
{
 total_lettre_entier = this.NumberToLetter(total_entier) + " Dirhams . " +this.NumberToLetter(total_decimal)+ " Centimes. " ;
}
else
{
 total_lettre_entier = this.NumberToLetter(total_entier) + " Dirhams.";
}



      let datrw = [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 3000,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: [
                                            new TextRun({
                                            text: "Référence",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  spacing: {
                    before: 100,
                    after: 100,
                  },
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),

            new TableCell({
              width: {
                size: 6000,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: [
                                            new TextRun({
                                            text: "Description",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),


            new TableCell({
              width: {
                size: 1000,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: [
                                            new TextRun({
                                            text: "TVA",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),

            new TableCell({
              width: {
                size: 2000,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: [
                                            new TextRun({
                                            text: "Quantité",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),

            new TableCell({
              width: {
                size: 2500,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: [
                                            new TextRun({
                                            text: "P.U.H.T",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),

            new TableCell({
              width: {
                size: 2500,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: [
                  new TextRun({
                                            text: "Total HT",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
          ],
        }),
      ];

      this.ArticlesList.forEach(function (item) {
        let r = new TableRow({
          children: [
            new TableCell({
              width: {
                size: 3000,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: [
                                        new TextRun({
                                            text: item.ref,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                  spacing: {
                    before: 100,
                    after: 100,
                    left: 10,
                    right: 10,
                  },
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),

            new TableCell({
              width: {
                size: 6000,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: [
                                        new TextRun({
                                            text: item.description,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                  spacing: {
                    before: 10,
                    after: 10,
                    left: 10,
                    right: 10,
                  },
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),



            new TableCell({
              width: {
                size: 1000,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                                    
                  children: [
                                        new TextRun({
                                          text: Number(item.tva).toFixed(0).toString() + "%",
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                  spacing: {
                    before: 10,
                    after: 10,
                    left: 10,
                    right: 10,
                  },
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),

            new TableCell({
              width: {
                size: 2000,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({

                  children: [
                                            new TextRun({
                                            text: item.qte.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],

                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
            new TableCell({
              width: {
                size: 2500,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: [
                                        new TextRun({
                                          text: item.prix_ht.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),

            new TableCell({
              width: {
                size: 2500,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  children: [
                                        new TextRun({
                                          text: Number(item.total_ht).toFixed(2).toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
          ],
        });

        datrw.push(r);
      });

      const table = new Table({
        rows: datrw,
      });

      //pied

      let datrw_salare_net = [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
  
                  children: [
                                            new TextRun({
                                              text: "Total HT",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  spacing: {
                    before: 80,
                    after: 80,
                  },
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),

            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  
                  
                  children: [
                                            new TextRun({
                                            text: this.total_price_ht.toString() + " DH",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
          ],
        }),

        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                                   
                  children: [
                                            new TextRun({
                                            text: "Total TVA",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  spacing: {
                    before: 80,
                    after: 80,
                  },
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),

            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  
                  
                  children: [
                                            new TextRun({
                                              text: this.total_tva.toString() + " DH",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
          ],
        }),

        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                  
                  children: [
                                            new TextRun({
                                            text: "Total TTC",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  spacing: {
                    before: 80,
                    after: 80,
                  },
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),

            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                new Paragraph({
                                    
                  children: [
                                            new TextRun({
                                              text: this.total_price.toString() + " DH",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                  heading: HeadingLevel.HEADING_3,
                  alignment: AlignmentType.CENTER,
                }),
              ],
            }),
          ],
        }),
      ];

      const tablenet = new Table({
        rows: datrw_salare_net,
      });

      //fin pie

            //arret

      let datrw_arret= [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:
                                    "  Arrêtée la présente facture à la somme de : ",
                                  color: "000000",
                                  bold: true,
                                  font: "Segoe UI",
                                }),
                              ],

                              heading: HeadingLevel.HEADING_4,
                              alignment: AlignmentType.LEFT,

                              spacing: {
                                after: 100,
                                before: 100,
                              },
                            }),



                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:" "+total_lettre_entier,
                                  color: "000000",
                                  bold: false,
                                  font: "Segoe UI",
                                }),
                              ],

                              heading: HeadingLevel.HEADING_5,
                              alignment: AlignmentType.LEFT,

                              spacing: {
                                after: 100,
                                before: 100,
                              },
                            }),
              ],
            }),

            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:
                                    " Conditions de règlement : ",
                                  color: "000000",
                                  bold: true,
                                  font: "Segoe UI",
                                }),
                              ],

                              heading: HeadingLevel.HEADING_4,
                              alignment: AlignmentType.LEFT,

                              spacing: {
                                after: 100,
                                before: 100,
                              },
                            }),



                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:"  "+this.mode,
                                  color: "000000",
                                  bold: false,
                                  font: "Segoe UI",
                                }),
                              ],

                              heading: HeadingLevel.HEADING_5,
                              alignment: AlignmentType.LEFT,

                              spacing: {
                                after: 100,
                                before: 100,
                              },
                            }),

              ],
            }),
          ],
        }),

      ];

      const tablearret = new Table({
        rows: datrw_arret,
      });

      //fin pie

const doc = new Document({

    sections: [
        {

        properties: {
          page: {
            pageNumbers: {
              start: 1,
              formatType: PageNumberFormat.DECIMAL,
            },
            margin:{
                                left:500,
                                right:500
                            }
          },
        },
        headers: {
          default: new Header({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: this.entet_pag,
                    color: "000000",
                    bold: true,
                  }),
                ],
                heading: HeadingLevel.TITLE,
                alignment: AlignmentType.CENTER,
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: this.sousentet_pag,
                    color: "000000",
                    bold: false,
                    font: "Segoe UI"
                  }),
                ],
                border: {
                  bottom: {
                    color: "000000",
                    space: 4,
                    value: "single",
                    size: 10,
                  },
                },
                heading: HeadingLevel.HEADING_1,
                alignment: AlignmentType.CENTER,
              }),
            ],
          }),
        },
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: this.pied_pag,
                    color: "000000",
                    bold: false,
                  }),
                ],
                border: {
                  top: {
                    color: "000000",
                    space: 4,
                    value: "single",
                    size: 10,
                  },
                },
                heading: HeadingLevel.HEADING_3,
                alignment: AlignmentType.CENTER,
              }),

              new Paragraph({
                alignment: AlignmentType.RIGHT,
                children: [
                  new TextRun({
                    children: ["Page : ", PageNumber.CURRENT],
                    color: "000000",
                  }),
                  new TextRun({
                    children: [" Sur ", PageNumber.TOTAL_PAGES],
                    color: "000000",
                  }),
                ],
              }),
            ],
          }),
        },

        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "Facture N° : "+this.nomBon,
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
            ],

            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.CENTER,

            spacing: {
              after: 200,
              before: 200,
            },
          }),

          new Paragraph({
            children: [
              new TextRun({
                text: "Client : ",
                color: "000000",
                bold: true,
                font: "Segoe UI",
              }),
              new TextRun({
                text: this.nClient,
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
              new TextRun({
                text: "          ICE : ",
                color: "000000",
                bold: true,
                font: "Segoe UI",
              }),

              new TextRun({
                text: this.ice,
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),

                            new TextRun({
                text: "          Date : ",
                color: "000000",
                bold: true,
                font: "Segoe UI",
              }),

              new TextRun({
                text: this.ddate,
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
            ],



            heading: HeadingLevel.HEADING_3,
            alignment: AlignmentType.CENTER,

            spacing: {
              after: 300,
              before: 100,
            },
          }),

          table,

          new Paragraph({
            children: [
              new TextRun({
                text: "",
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
            ],

            heading: HeadingLevel.HEADING_2,
            alignment: AlignmentType.CENTER,

            spacing: {
              after: 50,
              before: 50,
            },
          }),

          tablenet,
          new Paragraph({
            children: [
              new TextRun({
                text: "",
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
            ],

            heading: HeadingLevel.HEADING_2,
            alignment: AlignmentType.CENTER,

            spacing: {
              after: 50,
              before: 50,
            },
          }),
tablearret


        ],

        }]

});



      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, this.nomBon + ".docx");
      });
    },
    fetchCategorieList: function () {
      this.isLoading = true;
      this.$http
        .get("/categories")
        .then((result) => {
          this.listCat = result.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.flashMessage.show({
            status: "error",
            title: "Erreur de Chargement",
            message: "Impossible charger les données.. Actualisez et Réessayez",
          });
        });
    },
    cat_click(id_cat) {
      this.isLoading = true;
      this.$http
        .get("/articlesCat/" + id_cat)
        .then((result) => {
          this.listArt = result.data.data;

          this.isLoading = false;

          //effacer le search:
          this.serchArt = "";
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.flashMessage.show({
            status: "error",
            title: "Erreur de Chargement",
            message: "Impossible charger les données.. Actualisez et Réessayez",
          });
        });
    },
    art_click(id_art) {
      let art = this.listArt.findIndex((item) => item.id === id_art);
      this.itemForm.ref = this.listArt[art].ref;
      this.itemForm.code_bar = this.listArt[art].code_bar;
      this.itemForm.qte = 1;
      this.itemForm.description = this.listArt[art].description;
      this.itemForm.prix = this.listArt[art].prix_vent;
      this.itemForm.tva = this.listArt[art].tva;
      this.itemForm.unite = this.listArt[art].unite;
      this.itemForm.article_id = this.listArt[art].id;

      //si getion lot
      this.itemForm.is_lot_manag = this.listArt[art].is_lot_manag;

      //rechercher le lot
      this.fetchLots(id_art);
    },
    fetchLots(id_art) {
      this.isLoading = true;
      this.$http
        .get("/lotsArtDep/" + id_art + "/" + this.idDep)
        .then((result) => {
          this.ListLots = result.data.data;
          this.isLoading = false;

          if (this.ListLots.length > 0) {
            this.itemForm.lot_id = this.ListLots[0].id;
          } else {
            this.itemForm.lot_id = "";
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.flashMessage.show({
            status: "error",
            title: "Erreur de Chargement",
            message: "Impossible charger les données.. Actualisez et Réessayez",
          });
        });

      //lot desti
    },
    fetchListArts(id_bn) {
      this.isLoading = true;
      this.$http
        .get("/detailFactureClient/" + id_bn)
        .then((result) => {
          this.ArticlesList = result.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.flashMessage.show({
            status: "error",
            title: "Erreur de Chargement",
            message: "Impossible charger les données.. Actualisez et Réessayez",
          });
        });
    },
    handleSubmit() {
      //set nom lot
      if (this.itemForm.lot_id) {
        let am = this.ListLots.findIndex(
          (item) => item.id === this.itemForm.lot_id
        );
        this.itemForm.nom_lot = this.ListLots[am].nom;
      }

      this.isSubmitted = true;

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.flashMessage.show({
          status: "info",
          title: "Vérifiez les données et Reésayez !!",
        });
      } else {
        this.isLoading = true;

        this.$http
          .post("/factureClientDetail", {
            ref: this.itemForm.ref,
            description: this.itemForm.description,
            qte: this.itemForm.qte,
            prix: this.itemForm.prix,
            tva: this.itemForm.tva,
            unite: this.itemForm.unite,

            nom_lot: this.itemForm.nom_lot,
            lot_id: this.itemForm.lot_id,
            article_id: this.itemForm.article_id,
            facture_client_id: this.idBon,
          })
          .then((response) => {
            this.isLoading = false;
            this.ArticlesList.unshift(response.data.data);

            //desactiver la validation:
            this.isSubmitted = false;
            //afficher message
            this.flashMessage.show({
              status: "success",
              title: "Données sauvegardées",
              message: "Les données sont sauvegardées correctement ",
            });
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;

            this.flashMessage.show({
              status: "error",
              title: "Erreur De Sauvegarde",
              message: "Impossible  de sauvegarder les données .. Réessayez !!",
            });
          });
      }
    },
    del_click(itemA) {
      this.isLoading = true;

      this.$http
        .delete("/factureClientDetail/" + itemA.id)
        .then((response) => {
          console.log(response.data);
          this.isLoading = false;
          this.flashMessage.show({
            status: "success",
            title: "Données Supprimées",
            message: "Les données sont supprimées correctement",
          });
          let index = this.ArticlesList.findIndex(
            (item) => item.id === itemA.id
          );

          this.ArticlesList.splice(index, 1);
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;

          this.flashMessage.show({
            status: "error",
            title: "Erreur De Suppression",
            message: "Impossible  de supprimer les données .. Réessayez !!",
          });
        });
    },
    NewBon() {
      this.$router.push({
        name: "menuFacture",
      });
    },
    del_BonClick() {
      this.$confirm("Voulez-vous vraiment supprimer cette facture??")
        .then(() => {
          this.BnDell(this.idBon);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    BnDell(id_bn) {
      this.isLoading = true;

      this.$http
        .delete("/factureClients/" + id_bn)
        .then((response) => {
          console.log(response.data);
          this.isLoading = false;
          this.flashMessage.show({
            status: "success",
            title: "Données Supprimées",
            message: "Les données sont supprimées correctement",
          });

          //goto first page:
          this.$router.push({
            name: "factureClient",
          });
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;

          this.flashMessage.show({
            status: "error",
            title: "Erreur De Suppression",
            message: "Impossible  de supprimer les données .. Réessayez !!",
          });
        });
    },
    clickValiderBon() {
      this.$confirm("Voulez-vous vraiment VALIDER cette Facture??")
        .then(() => {
          this.validerBon(0);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    validerBon(reg) {
      //procedure du modification
      this.isLoading = true;

      this.$http
        .post("/validFactureClRegl", {
          date: this.itemVal.date,
          user: this.user_name,
          bank_compte_id: 0,
          id_bon: this.idBon,
          type: 1,
          regl: reg,
        })
        .then((response) => {
          this.isLoading = false;
          console.log(response.data);
          this.$bvModal.hide("validerModal");

          //goto first page:
          this.$router.push({
            name: "factureClient",
          });

          this.flashMessage.show({
            status: "success",
            title: "Données modifiées",
            message: "Les données sont modifiées correctement",
          });
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;

          this.flashMessage.show({
            status: "error",
            title: "Erreur De Modification",
            message: "Impossible  de modifier les données .. Réessayez !!",
          });
        });
    },
    AfficherTousArts() {
      this.cat_click(0)

    },
    Unite(nombre) {
      var unite;
      switch (nombre) {
        case 0:
          unite = "zéro";
          break;
        case 1:
          unite = "un";
          break;
        case 2:
          unite = "deux";
          break;
        case 3:
          unite = "trois";
          break;
        case 4:
          unite = "quatre";
          break;
        case 5:
          unite = "cinq";
          break;
        case 6:
          unite = "six";
          break;
        case 7:
          unite = "sept";
          break;
        case 8:
          unite = "huit";
          break;
        case 9:
          unite = "neuf";
          break;
      } //fin switch
      return unite;
    },

    Dizaine(nombre) {
         var dizaine;
      switch (nombre) {
        case 10:
          dizaine = "dix";
          break;
        case 11:
          dizaine = "onze";
          break;
        case 12:
          dizaine = "douze";
          break;
        case 13:
          dizaine = "treize";
          break;
        case 14:
          dizaine = "quatorze";
          break;
        case 15:
          dizaine = "quinze";
          break;
        case 16:
          dizaine = "seize";
          break;
        case 17:
          dizaine = "dix-sept";
          break;
        case 18:
          dizaine = "dix-huit";
          break;
        case 19:
          dizaine = "dix-neuf";
          break;
        case 20:
          dizaine = "vingt";
          break;
        case 30:
          dizaine = "trente";
          break;
        case 40:
          dizaine = "quarante";
          break;
        case 50:
          dizaine = "cinquante";
          break;
        case 60:
          dizaine = "soixante";
          break;
        case 70:
          dizaine = "soixante-dix";
          break;
        case 80:
          dizaine = "quatre-vingt";
          break;
        case 90:
          dizaine = "quatre-vingt-dix";
          break;
      } //fin switch
      return dizaine;
    },
    NumberToLetter(nombre) {
      var  quotient, reste, nb,n;
      var numberToLetter = "";
      //__________________________________

      if (nombre.toString().replace(/ /gi, "").length > 15)
        return "dépassement de capacité";
      if (isNaN(nombre.toString().replace(/ /gi, "")))
        return "Nombre non valide";

      nb = parseFloat(nombre.toString().replace(/ /gi, ""));
      if (Math.ceil(nb) != nb) return "Nombre avec virgule non géré.";

      n = nb.toString().length;
      switch (n) {
        case 1:
          numberToLetter = this.Unite(nb);
          break;
        case 2:
          if (nb > 19) {
            quotient = Math.floor(nb / 10);
            reste = nb % 10;
            if (nb < 71 || (nb > 79 && nb < 91)) {
              if (reste == 0) numberToLetter = this.Dizaine(quotient * 10);
              if (reste == 1)
                numberToLetter = this.Dizaine(quotient * 10) + "-et-" + this.Unite(reste);
              if (reste > 1)
                numberToLetter = this.Dizaine(quotient * 10) + "-" + this.Unite(reste);
            } else
              numberToLetter =
                this.Dizaine((quotient - 1) * 10) + "-" + this.Dizaine(10 + reste);
          } else numberToLetter = this.Dizaine(nb);
          break;
        case 3:
          quotient = Math.floor(nb / 100);
          reste = nb % 100;
          if (quotient == 1 && reste == 0) numberToLetter = "cent";
          if (quotient == 1 && reste != 0)
            numberToLetter = "cent" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.Unite(quotient) + " cents";
          if (quotient > 1 && reste != 0)
            numberToLetter = this.Unite(quotient) + " cent " + this.NumberToLetter(reste);
          break;
        case 4:
          quotient = Math.floor(nb / 1000);
          reste = nb - quotient * 1000;
          if (quotient == 1 && reste == 0) numberToLetter = "mille";
          if (quotient == 1 && reste != 0)
            numberToLetter = "mille" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " mille";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " mille " + this.NumberToLetter(reste);
          break;
        case 5:
          quotient = Math.floor(nb / 1000);
          reste = nb - quotient * 1000;
          if (quotient == 1 && reste == 0) numberToLetter = "mille";
          if (quotient == 1 && reste != 0)
            numberToLetter = "mille" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " mille";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " mille " + this.NumberToLetter(reste);
          break;
        case 6:
          quotient = Math.floor(nb / 1000);
          reste = nb - quotient * 1000;
          if (quotient == 1 && reste == 0) numberToLetter = "mille";
          if (quotient == 1 && reste != 0)
            numberToLetter = "mille" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " mille";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " mille " + this.NumberToLetter(reste);
          break;
        case 7:
          quotient = Math.floor(nb / 1000000);
          reste = nb % 1000000;
          if (quotient == 1 && reste == 0) numberToLetter = "un million";
          if (quotient == 1 && reste != 0)
            numberToLetter = "un million" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " millions";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " millions " + this.NumberToLetter(reste);
          break;
        case 8:
          quotient = Math.floor(nb / 1000000);
          reste = nb % 1000000;
          if (quotient == 1 && reste == 0) numberToLetter = "un million";
          if (quotient == 1 && reste != 0)
            numberToLetter = "un million" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " millions";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " millions " + this.NumberToLetter(reste);
          break;
        case 9:
          quotient = Math.floor(nb / 1000000);
          reste = nb % 1000000;
          if (quotient == 1 && reste == 0) numberToLetter = "un million";
          if (quotient == 1 && reste != 0)
            numberToLetter = "un million" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " millions";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " millions " + this.NumberToLetter(reste);
          break;
        case 10:
          quotient = Math.floor(nb / 1000000000);
          reste = nb - quotient * 1000000000;
          if (quotient == 1 && reste == 0) numberToLetter = "un milliard";
          if (quotient == 1 && reste != 0)
            numberToLetter = "un milliard" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " milliards";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " milliards " + this.NumberToLetter(reste);
          break;
        case 11:
          quotient = Math.floor(nb / 1000000000);
          reste = nb - quotient * 1000000000;
          if (quotient == 1 && reste == 0) numberToLetter = "un milliard";
          if (quotient == 1 && reste != 0)
            numberToLetter = "un milliard" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " milliards";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " milliards " + this.NumberToLetter(reste);
          break;
        case 12:
          quotient = Math.floor(nb / 1000000000);
          reste = nb - quotient * 1000000000;
          if (quotient == 1 && reste == 0) numberToLetter = "un milliard";
          if (quotient == 1 && reste != 0)
            numberToLetter = "un milliard" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " milliards";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " milliards " + this.NumberToLetter(reste);
          break;
        case 13:
          quotient = Math.floor(nb / 1000000000000);
          reste = nb - quotient * 1000000000000;
          if (quotient == 1 && reste == 0) numberToLetter = "un billion";
          if (quotient == 1 && reste != 0)
            numberToLetter = "un billion" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " billions";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " billions " + this.NumberToLetter(reste);
          break;
        case 14:
          quotient = Math.floor(nb / 1000000000000);
          reste = nb - quotient * 1000000000000;
          if (quotient == 1 && reste == 0) numberToLetter = "un billion";
          if (quotient == 1 && reste != 0)
            numberToLetter = "un billion" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " billions";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " billions " + this.NumberToLetter(reste);
          break;
        case 15:
          quotient = Math.floor(nb / 1000000000000);
          reste = nb - quotient * 1000000000000;
          if (quotient == 1 && reste == 0) numberToLetter = "un billion";
          if (quotient == 1 && reste != 0)
            numberToLetter = "un billion" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " billions";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " billions " + this.NumberToLetter(reste);
          break;
      } //fin switch
      /*respect de l'accord de quatre-vingt*/
      if (
        numberToLetter.substr(
          numberToLetter.length - "quatre-vingt".length,
          "quatre-vingt".length
        ) == "quatre-vingt"
      )
        numberToLetter = numberToLetter + "s";

      return numberToLetter;
    }, //-----------------------------------------------------------------------
  },
  components: {
    Loading,
  },
};
</script>

<style>
/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #9cc7f9;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #9cc7f9;
}
::-webkit-scrollbar-thumb:active {
  background: #9cc7f9;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 60px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
</style>
<template>
  
<div  class="card  shadow border border-white bg-white h-100 pb-3 overflow-auto">


        <div class="row pb-1 border-bottom border-info m-3">


                <h4 class="font-weight-lighter var_color"> <span class="fa fa-file-prescription"></span> Bons De Reteur Client</h4> 
            
        </div>

          <div class="row m-5">

        <div class="col-md-8 ml-auto mr-auto">

           <form @submit.prevent="handleSubmitBonCMD()">

            <div class="row  mt-2">
            <div class="form-group col">
                <label for="qte">N° Bon  :</label>
                <input type="text" v-model="itemFormBon.nom" id="qte" name="qte" class="form-control"
                 :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.nom.$error }"/>
                 
                <div v-if="isSubmitted && !$v.itemFormBon.nom.required" class="invalid-feedback">Entrez Le N° de bon !!</div>


            </div>


            <div class="form-group col">
                <label for="date_exp" class="mr-sm-2">Date :</label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-date_exp"
                    placeholder="date"
                    v-model="itemFormBon.date"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>


            </div>



            <div class="row mt-2">
                        <div class="form-group col">

                            <label for="client_id">Client :</label>

                            <b-button  class="mr-2 p-0 mt-0 float-right text-secondary" variant="link" @click="client_search_click()">
                                            <i class="fa fa-search"></i>
                                </b-button>

                            <select class="form-control" id="client_id" v-model="itemFormBon.client_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.client_id.$error }">
                            <option v-for="item in ListClients" :key="item.id" v-bind:value="item.id">   
                                {{ item.code }} - {{ item.nom_prenom }} - ( {{ item.cin }} )
                            </option>

                            </select>

                             <div v-if="isSubmitted && !$v.itemFormBon.client_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                         </div>

                         <div class="form-group col">

                            <label for="depot_id">Dépôt :</label>
                            <select :disabled="!nom_dis" class="form-control" id="depot_id" v-model="itemFormBon.depot_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.depot_id.$error }">
                            <option v-for="item in depots" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>

                             <div v-if="isSubmitted && !$v.itemFormBon.depot_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                         </div>
            </div>


            <div class="form-group mt-2">
            <label for="adresse">Note :</label>
            <textarea name="adresse" id="adresse"  rows="2" class="form-control" v-model="itemFormBon.description"></textarea>
            </div>


            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Suivant</button>
            </div>

        </form>

        </div>   

</div> 

    
<b-modal id="searchModal" title="Rechercher" hide-footer>


<div style="height: 400px; background-color: rgba(255,0,0,0.1);">
    
                <div class="card border border-light shadow h-100  overflow-auto">
                <div class="card-header  bg-light border-light p-1">
                    <b-form-input v-model="serchArt"  class="border-light" id="search-art" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                
                            <div class="custom-control custom-switch form-control-sm ml-1">
                            <input type="checkbox"
                            class="custom-control-input" 
                            id="isCodeBarreSearch"
                                    v-model="isCodeBarreSearch"
                                    name="isCodeBarreSearch"
                                    value=true
                                    unchecked-value=false>

                            <label class="custom-control-label text-info  mb-0" for="isCodeBarreSearch">Code / CIN</label>
                            </div>
                </div>

                <b-list-group flush  >
           
                <b-list-group-item  v-for="item in filterArticle" :key="item.id" button class="p-2 mt-2" variant="info" @click="client_list_click(item)">
                    {{ item.code }} - {{ item.nom_prenom }} - ( {{ item.cin }} )
                     </b-list-group-item>

              

                </b-list-group>
                </div>
</div>

</b-modal>


      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";

export default {
 props: ['ResivedId'],
 computed: {
    filterArticle() {
            return this.ListClients.filter(article => {

            if(this.isCodeBarreSearch)
            {
                if(article.code)
                {
                    return article.code.toLowerCase().includes(this.serchArt.toLowerCase())|| article.cin.toLowerCase().includes(this.serchArt.toLowerCase())
                }

            }
            else
            {
                return article.nom_prenom.toLowerCase().includes(this.serchArt.toLowerCase())
            }

      })
    },
    user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
    user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    },

  },
mounted(){

this.fetchClientsDepots()

if(this.ResivedId)
{
this.process_modification(this.ResivedId)
}
else
{
this.process_Ajouter()
}

    },
data () {
		return {

            ListClients:[],
            depots:[],
            itemFormBon: {
                    id:"",
                    nom:'BR-C',
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    valider:false,
                    regler:false,
                    total_ttc:0,
                    description:'',
                    client_id:'',
                    depot_id:'',
                    user:'user'},

            isSubmitted: false,
            isLoading: false,
            isModification:false,


            //boninfos Champs disibl:
            nom_dis:true,
        
            isCodeBarreSearch: false,
            serchArt:'',

		}
    },
    validations: {
            itemFormBon: {
                nom: {
                    required
                },
                date: {
                    required
                },
                valider: {
                    required
                },
                total_ttc: {
                    required
                },
                regler: {
                    required
                },
                depot_id: {
                    required
                },
                client_id: {
                    required
                },
                user: {
                    required
                }
            }

    },
methods:{
    client_search_click()
{
    this.$root.$emit('bv::show::modal', 'searchModal' )
},

client_list_click(clt)
{
    this.itemFormBon.client_id = clt.id

     this.$bvModal.hide('searchModal')
},

        process_modification(id_resived)
        {
                    this.isLoading=true;
                    this.$http.get('/bretourClient/'+id_resived)
                    .then((result) => {
                    
                    //affectation des information
                    this.itemFormBon.id=result.data.data.id,
                    this.itemFormBon.nom=result.data.data.nom,
                    this.itemFormBon.date=result.data.data.date,
                    this.itemFormBon.valider=result.data.data.valider,
                    this.itemFormBon.regler=result.data.data.regler,
                    this.itemFormBon.total_ttc=result.data.data.total_ttc,
                    this.itemFormBon.description=result.data.data.description,
                    this.itemFormBon.client_id=result.data.data.client_id,
                    this.itemFormBon.depot_id=result.data.data.depot_id,
                    this.itemFormBon.user=result.data.data.user




                    //boninfos Champs disibl:
                        this.nom_dis=false,
                     


                     //Activer la modification
                     this.isModification=true,


                     //desible load
                     this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        process_Ajouter()
        {

            this.itemFormBon.total_ttc=0

                    //boninfos Champs disibl:
                        this.nom_dis=true,
              

                     //Activer la modification
                     this.isModification=false

        },

        fetchClientsDepots: function () {

                    //chager les clients:
                    this.isLoading=true;
                    this.$http.get('/clientMinDeZone/0')
                    .then((result) => {
                                this.ListClients = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


                    //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/depotPerson/'+this.user_dep)
                    .then((result) => {
                                this.depots = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        handleSubmitBonCMD(){

                //USER HANDLE
                this.itemFormBon.user= this.user_name

                
                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                    if(this.isModification)
                    {
                
                    this.edit_bon()
                    }
                    else
                    {
                    
                    this.add_bon()
                    }
   

                }


        },
        add_bon()
        {

            this.isLoading=true;

            this.$http.post('/bretourClient',{

                    nom:this.itemFormBon.nom,
                    date:this.itemFormBon.date,
                    valider:this.itemFormBon.valider,
                    regler:this.itemFormBon.regler,
                    total_ttc:this.itemFormBon.total_ttc,
                    description:this.itemFormBon.description,
                    depot_id:this.itemFormBon.depot_id,
                    client_id:this.itemFormBon.client_id,
                    user:this.itemFormBon.user


                })
                .then(response => {
                    //metre a jour id
                    this.itemFormBon.id=response.data.data.id,
                    this.itemFormBon.nom=response.data.data.nom,

                    //boninfos Champs disibl:
                    this.nom_dis=false,
          


                    //Activer la modification
                    this.isModification=true,


                    this.isLoading=false;
                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '


                })
                    //afficher les details:

                   let four = this.ListClients.findIndex(item => item.id === response.data.data.client_id)
                    let dep = this.depots.findIndex(item => item.id === response.data.data.depot_id)

                    this.preparerDetail(
                    response.data.data.id,
                    response.data.data.nom,
                    response.data.data.client_id,
                    this.ListClients[four].nom_prenom,
                    response.data.data.depot_id,
                    this.depots[dep].nom,
                    response.data.data.date
                    )

                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });


        },
        edit_bon()
        {

            this.isLoading=true;

            this.$http.patch('/bretourClient/'+this.itemFormBon.id,{

                    nom:this.itemFormBon.nom,
                    date:this.itemFormBon.date,
                    valider:this.itemFormBon.valider,
                    regler:this.itemFormBon.regler,
                    total_ttc:this.itemFormBon.total_ttc,
                    description:this.itemFormBon.description,
                    depot_id:this.itemFormBon.depot_id,
                    client_id:this.itemFormBon.client_id,
                    user:this.itemFormBon.user
                })
                .then(response => {

                    this.isLoading=false;
                    console.log(response.data.data);


                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })

                //afficahge detail

                    let four = this.ListClients.findIndex(item => item.id === response.data.data.client_id)
                    let dep = this.depots.findIndex(item => item.id === response.data.data.depot_id)

                    this.preparerDetail(
                    response.data.data.id,
                    response.data.data.nom,
                    response.data.data.client_id,
                    this.ListClients[four].nom_prenom,
                    response.data.data.depot_id,
                    this.depots[dep].nom,
                    response.data.data.date
                    )

                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });


        },
        preparerDetail(id_bon,nom,id_four,nom_fou,id_dep,nomDep,ddat)
        {

                        this.$router.push({ 
                        name: 'bnRetrClientsDetail', 
                        params: { 
                        idBon: id_bon,
                        nomBon: nom,
                        idFour: id_four,
                        nClient: nom_fou,
                        idDep:id_dep,
                        nDep:nomDep,
                        ddate:ddat
                        } 
                            });
        }

        



},
components: {
              Loading      
}


}
</script>

<style>
.var_color{
  color: #2faaa5;
}
</style>
<template>
<div>
  
                        

      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    


            <h4 class="col ml-3 font-weight-lighter var_color"> <span class="fa fa-file-invoice"></span> Factures Clients ( Validés )    </h4> 

                    <div class="col">

                                    <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                                                <i class="fa fa-home fa-2x"></i>
                                    </b-button>

                                    <b-button pill size="sm"  class="m-2 float-right" variant="info" @click="fetchList">
                                        <i class="fa fa-sync-alt"></i>
                                        <span> Actualiser</span> 
                                    </b-button>
                    </div>
            </div>
       



        <b-table 
        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
            <template #cell(actions)="row">
                    <b-button v-if="d_gst_fact_val==true" pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-warning" @click="devald_click(row.item)">
                                <i class="fa fa-check"></i>
                        
                    </b-button>
                    <b-button v-if="d_gst_fact_val==true" pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-primary" @click="edit_click(row.item)">
                            <i class="fa fa-pencil-alt"></i>
                    
                    </b-button>

                                        
                    <b-button v-if="d_is_entete==true" pill size="sm" class="mr-2 mt-1 mb-1 float-right  mb-1" variant="outline-info" @click="Ckick_exportDox(row.item,1)">
                            <i class="fa fa-print"></i>
                    
                    </b-button>

                    <b-button v-if="d_gst_fact_val==true" pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-secondary" @click="Ckick_exportDox(row.item,0)">
                            <i class="fa fa-print"></i>
                    
                    </b-button>
                    <b-button v-if="d_is_print_pdf==true" pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-warning" @click="Ckick_exportDox(row.item,2)">
                            <i class="fa fa-print"></i> 
                    </b-button>

                    <b-button pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-info" @click="fetchListArts(row.item)">
                            <i class="fa fa-bars"></i>
                    
                    </b-button>

                </template>
        
        </b-table>
                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>

      </div>


    <!-- le modal -->
    <b-modal id="detailModal" :title=curentBN size="lg" hide-footer>


       <b-table 
        small 
        borderless
        striped 
        sticky-header
        :fields="fieldsArticle" 
        :items="ArticlesList"
        >

        
        </b-table>
              <div class="card border-info">
                        <div class=" d-flex justify-content-center pt-1 text-info">
                                <h5 class="font-weight-light ">Total Prix : {{total_price}} </h5>
                        </div>
              </div>
    </b-modal>
    <!-- les trucs public -->

<loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
 import { ImageRun,HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
 import { saveAs } from 'file-saver';
 import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export default {
    computed: {
              //3t pdf
              get_entete3t() {
      return this.$store.getters.get_entete3t;
    },  
    get_email_3t() {
      return this.$store.getters.get_email_3t;
    },
    get_contact_3t() {
      return this.$store.getters.get_contact_3t;
    },
    get_pied_pag_pdf3t1() {
      return this.$store.getters.get_pied_pag_pdf3t1;
    },
    get_pied_pag_pdf3t2() {
      return this.$store.getters.get_pied_pag_pdf3t2;
    },
    get_pied_pag_pdf3t3() {
      return this.$store.getters.get_pied_pag_pdf3t3;
    }, 
    //fin 3t pdf
                            d_is_logo() {
      return this.$store.getters.get_is_logo;
    },
             d_logo_w() {
      return this.$store.getters.get_logo_w;
    },
            d_logo_h() {
      return this.$store.getters.get_logo_h;
    },
            d_logo_img() {
      return this.$store.getters.get_logo_img;
    }, 
         d_img_logo_pdf() {
      return this.$store.getters.get_img_logo_pdf;
    }, 
    d_logo_w_pdf() {
      return this.$store.getters.get_logo_w_pdf;
    },
    d_logo_h_pdf() {
      return this.$store.getters.get_logo_h_pdf;
    },
    d_pied_pag_pdf1() {
      return this.$store.getters.get_pied_pag_pdf1;
    },
    d_pied_pag_pdf2() {
      return this.$store.getters.get_pied_pag_pdf2;
    },
    d_pied_pag_pdf3() {
      return this.$store.getters.get_pied_pag_pdf3;
    },
    d_is_print_pdf() {
      return this.$store.getters.get_is_print_pdf;
    },
    d_is_entete() {
      return this.$store.getters.get_is_entete;
    },
             d_logo_e() {
      return this.$store.getters.get_logo_e;
    },
            d_logo_p() {
      return this.$store.getters.get_logo_p;
    },

            user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
                entet_pag() {

      if(this.$store.getters.get_entete)
      {
            return this.$store.getters.get_entete;
      }
      else
      {
            return '';
      }
    },
    sousentet_pag() {

      if(this.$store.getters.get_sousentete)
      {
            return this.$store.getters.get_sousentete;
      }
      else
      {
            return '';
      }
    },
    pied_pag() {

      if(this.$store.getters.get_pied)
      {
            return this.$store.getters.get_pied;
      }
      else
      {
            return '';
      }
    },
              rows_pagin() {
        return this.itemslist.length
      },
            user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    },
    d_gst_fact_val() {
      return this.$store.getters.get_gst_fact_val;
    },
        total_price: function() {
      return this.ArticlesList.reduce((a, b) => +a + +(b.qte * b.prix), 0);
    }
    },
    mounted(){
        this.fetchList()
    },
	data () {
		return {
            dateToday: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
            perPage: 10,
            currentPage: 1,
            fieldsArticle: [
                {key: 'ref', label: 'Réf'},
                {key: 'qte', label: 'Quantité'},
                 {key: 'unite', label: 'Unité'},
                {key: 'prix', label: 'Prix'},
                {key: 'nom_lot', label: 'Lot'},
                {key: 'description', label: 'Description'}
            ],
            ArticlesList:[],

            curentBN:'',

            itemslist: [],

            fields: [
                {key: 'nom', label: 'N°', sortable: true},
                {key: 'client', label: 'Client', sortable: true},
                {key: 'is_stock', label: 'Pérsonnalisé', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }, sortable: true},
                {key: 'date', label: 'Date', sortable: true},
                {key: 'total_ttc', label: 'Total TTC', sortable: true},
                {key: 'total_tva', label: 'Total TVA', sortable: true},
                {key: 'total_ht', label: 'Total HT', sortable: true},
                 {key: 'user', label: 'Utilisateur', sortable: true},
                { key: 'actions', label: '' }
            ],
            //public trucs

            isLoading: false
		}
	},
    validations: {
            itemForm: {
                nom: {
                    required
                }
            }
    },
    methods :{

    export_pdf(rowitem)
    {

          var imgData=this.d_img_logo_pdf;

          var imgData_w=this.d_logo_w_pdf;
          var imgData_h=this.d_logo_h_pdf;



    //3t pdf
    var local_entet_pag = this.get_entete3t
      var local_get_email_3t = this.get_email_3t
      var local_get_contact_3t = this.get_contact_3t


      var piedPg_local= this.get_pied_pag_pdf3t1;
      var piedPg_local2= this.get_pied_pag_pdf3t2;
      var piedPg_local3= this.get_pied_pag_pdf3t3
    //fin 3t pdf 



          let number_pric= rowitem.total_ttc;

          let total_entier = parseInt(number_pric);
          let total_decimal = Math.round((number_pric - parseInt(number_pric))*100);


          let total_lettre_entier ="";

          if(total_decimal>0)
          {
          total_lettre_entier = this.NumberToLetter(total_entier) + " Dirhams . " +this.NumberToLetter(total_decimal)+ " Centimes. " ;
          }
          else
          {
          total_lettre_entier = this.NumberToLetter(total_entier) + " Dirhams.";
          }





          var totalht_local = rowitem.total_ht.toString() + " DH";

          var totaltva_local = rowitem.total_tva.toString() + " DH";
          var totalttc_local = rowitem.total_ttc.toString() + " DH";
          var les_totaux="Total HT : "+totalht_local+"\nTotal T.V.A : "+totaltva_local+"\nTotal TTC : "+totalttc_local


          var n_local = rowitem.nom;
          var date_local = rowitem.date;

          var client_local = rowitem.client;

          var ice_local = rowitem.ice;
          var mode_local = rowitem.mode;
          
          if(ice_local==null)
          {
            ice_local=""
          }
          const doc = new jsPDF();



          autoTable(doc, {
            body: [
                [
                {
                    content: '',
                    styles: {
                    halign: 'left'
                    }
                },
                {
                    content: '',
                    styles: {
                    halign: 'right'
                    }
                }
                ],
            ],

                didDrawCell: function (data) {

            if (data.section === 'body' && data.column.dataKey === 0) {


                    doc.addImage(imgData,'JPEG',data.cell.x,data.cell.y,imgData_w,imgData_h);
                    

            }


            if (data.section === 'body' && data.column.dataKey === 1) {
                doc.autoTable({
                startY: data.cell.y + 0.7,
                margin: { left: data.cell.x },
                tableWidth: data.cell.width - 2,
                styles: {
                    maxCellHeight: 5,
                },

                body: [
                    [
                        {
                        content: local_entet_pag,
                        styles: {
                            halign: 'right',
                            fontSize : 11,
                            textColor : '#000000',
                        
                            lineColor:'#89663c', 
                            lineWidth:{top: 0, right: 1.5, bottom: 0, left: 0},
                            cellPadding: {top: 0, right: 3, bottom: 0, left: 0},

                        },
                        
                        },


                    ]
            ],
            theme: 'grid'
                })
            

                                        
            }





            },



            theme: 'plain',
            styles: { minCellHeight: 18 },
            margin: {bottom: 0,left:7 ,right:7 ,top:9},
            columnStyles: {
                0: {
                    cellWidth: 80
                },
                1: {
                    cellWidth: 'auto'
                }}



            });


        //table nom bon 
        autoTable(doc, {
              body: [
                [
                  {
                    styles: {                   
                    lineColor:'#89663c', 
                    minCellHeight:10,
                    lineWidth:{top: 0, right: 1.5, bottom: 0, left: 0},
                    cellPadding: {top: 0, right: 3, bottom: 0, left: 0},

                  },
                  },
                
                ],
              ],

                        didDrawCell: (data) => {
                            if (data.section === 'body' && data.column.dataKey === 0) {
                               doc.setFillColor(22, 54, 92);
                               doc.setDrawColor(22, 54, 92);
                               doc.roundedRect( data.cell.x , data.cell.y, data.cell.width-3, data.cell.height, 0, 3, 'FD');
                                  doc.setFontSize(16)
                                  doc.setTextColor(255,255,255)
                                 doc.text('F A C T U R E', data.cell.x + 18, data.cell.y+7)

         

                            }


                        },


cellPadding:{left: 6 },
    margin: {left: 125,right:9 },

    startY: 39,

      theme: 'grid',

    });

        //ligne
            doc.setDrawColor(22,54,92);
          doc.setLineWidth(0.5);
          doc.line(7, 52, 202, 52);

        //client

        autoTable(doc, {
          body: [
            [
              {
                styles: {
                  halign: 'right'
                }
              }
            ],
          ],

        didDrawCell: function (data) {
          if (data.section === 'body' && data.column.dataKey === 0) {
            doc.autoTable({

              startY: data.cell.y + 2,
              margin: { left: data.cell.x + 2 },
              tableWidth: data.cell.width - 4,
              styles: {
                maxCellHeight: 4,
                minCellWidth: 30
              },

            body: [
                  [
                    {
                      content: 'Client : ',
                      styles: {
                        halign: 'left',
                        fontStyle:'bold',
                        fontSize : 11,
                        textColor : '#2f64a4',
                      lineWidth:{top: 0, right: 0, bottom: 0, left: 0}
                      }
                    },

                    {
                      content: client_local,
                      styles: {
                        halign: 'left',
                        fontSize : 11,
                        textColor : '#000000',
                        lineColor:'#16365c', 
                      lineWidth:{top: 0, right: 0, bottom: 0, left: 0}

                      }
                    }
                  ],

                  [
                    {
                      content: 'ICE : ',
                      styles: {
                        halign: 'left',
                        fontStyle:'bold',
                        fontSize : 11,
                        textColor : '#2f64a4',
                        lineColor:'#16365c', 
                        lineWidth:{top: 0, right: 0, bottom: 0, left: 0}
                      }
                    },

                    {
                      content: ice_local+' ',
                      styles: {
                        halign: 'left',
                        fontSize : 11,
                        textColor : '#000000',
                        lineColor:'#16365c', 
                        lineWidth:{top: 0, right: 0, bottom: 0, left: 0},
                      }
                    }
                  ],

          ],

          theme: 'grid',

            })
          
                                    
          }





        },



          theme: 'plain',
          styles: { minCellHeight: 30.5 },

            margin: { right: 120 },
            startY: 56,


        });

        //data et num

        autoTable(doc, {
          body: [
            [
              {
                styles: {
                  halign: 'right'
                }
              }
            ],
          ],

        didDrawCell: function (data) {
          if (data.section === 'body' && data.column.dataKey === 0) {
            doc.autoTable({

              startY: data.cell.y + 2,
              margin: { left: data.cell.x + 2 },
              tableWidth: data.cell.width - 4,
              styles: {
                maxCellHeight: 4,
                minCellWidth: 30
              },

            body: [
                  [
                    {
                      content: 'Date : ',
                      styles: {
                        halign: 'left',
                        fontStyle:'bold',
                        fontSize : 11,
                        textColor : '#2f64a4',
                      lineWidth:{top: 0, right: 0, bottom: 0, left: 0}
                      }
                    },

                    {
                      content: date_local,
                      styles: {
                        halign: 'left',
                        fontSize : 11,
                        textColor : '#000000',
                      lineWidth:{top: 0, right: 0, bottom: 0, left: 0}

                      }
                    }
                  ],

                  [
                    {
                      content: 'Bon N° : ',
                      styles: {
                        halign: 'left',
                        fontStyle:'bold',
                        fontSize : 11,
                        textColor : '#2f64a4',
                        lineWidth:{top: 0, right: 0, bottom: 0, left: 0}
                      }
                    },

                    {
                      content: n_local+' ',
                      styles: {
                        halign: 'left',
                        fontSize : 11,
                        textColor : '#000000',
                        lineWidth:{top: 0, right: 0, bottom: 0, left: 0}
                      }
                    }
                  ],

          ],

          theme: 'grid',

            })
          
                                    
          }





        },



          theme: 'plain',
          styles: { minCellHeight: 30.5 },

            margin: { left: 107 },
            startY: 56,


        });




          let article_body=[]

          this.ArticlesList.forEach(function (item) {


            article_body.push([item.ref, item.description, Number(item.tva).toFixed(0).toString() + "%", item.qte.toString(), item.prix_ht.toString(), item.total_ht.toString()]);
          });



       

          autoTable(doc, {
      head: [['Référence', 'Description', 'TVA', 'Quantité', 'P.U.H.T', 'Total HT']],
      body: article_body,
      foot: [[{"content":"","colSpan":3},{"content":les_totaux,"colSpan":3}],
      [{"content":"-Arrêtée la présente facture à la somme de : "+total_lettre_entier,"colSpan":6}],
      [{"content":"-Conditions de règlement : "+mode_local,"colSpan":6}]],
      
      textColor : '#000000',
      startY:80,
      //margin: {bottom: 40 },
      margin: {bottom: 45,left:8 ,right:8 ,top:8},
      didDrawPage: function (data) {

            // Footer
            //  var str = 'P:' + doc.internal.getNumberOfPages()

          doc.setFontSize(11)

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize
          var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()




    //foter

      autoTable(doc, {
      body: [
        [
          {
            styles: {
              halign: 'right'
            },
           
          },
          {
            styles: {
              halign: 'right'
            },
           
          },
          {
            styles: {
              halign: 'right'
            },
           
          }
        ],
      ],

    didDrawCell: function (data) {
      if (data.section === 'body' && data.column.dataKey === 0) {
        doc.autoTable({

          startY: data.cell.y + 2,
          margin: { left: data.cell.x + 2 },
          tableWidth: data.cell.width - 4,
          styles: {
            maxCellHeight: 4,
            minCellWidth: 30
          },



        body: [
              [
                {
                  content: piedPg_local,
                  styles: {
                    halign: 'left',
                    fontSize : 9,
                    textColor : '#000000',
                  lineWidth:{top: 0, right: 0, bottom: 0, left: 0}

                  }
                }
              ],

      ],

       theme: 'grid',

        })
      
                                 
      }
      if (data.section === 'body' && data.column.dataKey === 1) {
        doc.autoTable({

          startY: data.cell.y + 2,
          margin: { left: data.cell.x + 2 },
          tableWidth: data.cell.width - 4,
          styles: {
            maxCellHeight: 4,
            minCellWidth: 30
          },

        body: [
              [
                {
                  content: piedPg_local2,
                  styles: {
                    halign: 'left',
                    fontSize : 9,
                    textColor : '#000000',
                  lineWidth:{top: 0, right: 0, bottom: 0, left: 0}

                  }
                }
              ],

      ],

       theme: 'grid',

        })
      
                                 
      }

      if (data.section === 'body' && data.column.dataKey === 2) {
        doc.autoTable({

          startY: data.cell.y + 2,
          margin: { left: data.cell.x + 2 },
          tableWidth: data.cell.width - 4,
          styles: {
            maxCellHeight: 4,
            minCellWidth: 30
          },

        body: [
              [
                {
                  content: piedPg_local3,
                  styles: {
                    halign: 'left',
                    fontSize : 9,
                    textColor : '#000000',
                  lineWidth:{top: 0, right: 0, bottom: 0, left: 0}

                  }
                }
              ],

      ],

       theme: 'grid',

        })
      
                                 
      }
    },



      theme: 'plain',
       styles: { minCellHeight: 10.5 },
       margin: {bottom: 0,left:8 ,right:8 ,top:0},
      //  margin: { left: 107 },
        startY: pageHeight - 43
        ,


    });


        doc.setTextColor(72,82,220)
          doc.text(local_get_email_3t, data.settings.margin.left+8, pageHeight - 10)
          doc.text(local_get_contact_3t, 170, pageHeight - 10)

          doc.setDrawColor(22,54,92);
          doc.setLineWidth(0.5);
          doc.line(10, pageHeight - 15, 200, pageHeight - 15);




    },

 
      theme: 'striped',
      headStyles:{
        textColor : '#FFFFFF',
        fontSize : 12,
        fillColor: '#16365c'
      },
      footStyles:{
        textColor : '#000000',
        fontSize : 12,
        fillColor: '#9ac2f2',
      cellPadding:{bottom: 3,left:4 ,right:0 ,top:3},
     // minCellHeight:15
      },
      showFoot: "lastPage"

    });





        doc.save('Facture '+n_local+'_'+client_local+'.pdf');




    },
    //fin export pdf



Ckick_exportDox(rowitem,ent)
{
                    this.isLoading=true;
                    this.$http.get('/detailFactureClient/'+rowitem.id)
                    .then((result) => {
                                this.ArticlesList = result.data.data
                                this.isLoading=false;




                            if(ent==0)
                            {
                                if(this.d_is_logo == true)
                                {
                                    this.exportDoxLogo(rowitem)
                                }
                                else
                                {
                                    this.exportDox(rowitem)
                                }

                            }
                            else if(ent==1)
                            {

                            this.exportDoxEntet(rowitem)
                            }
                            else if(ent==2)
                            {

                            this.export_pdf(rowitem)
                            }


                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
},


exportDoxEntet(rowitem)
        {

let number_pric= rowitem.total_ttc;

let total_entier = parseInt(number_pric);
let total_decimal = Math.round((number_pric - parseInt(number_pric))*100);


let total_lettre_entier ="";

if(total_decimal>0)
{
 total_lettre_entier = this.NumberToLetter(total_entier) + " Dirhams . " +this.NumberToLetter(total_decimal)+ " Centimes. " ;
}
else
{
 total_lettre_entier = this.NumberToLetter(total_entier) + " Dirhams.";
}




let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        children: [
                                            new TextRun({
                                            text: "Référence",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 6000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                            children: [
                                            new TextRun({
                                            text: "Description",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),




                        new TableCell({
                            width: {
                                size: 1000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                         children: [
                                            new TextRun({
                                            text: "TVA",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        children: [
                                            new TextRun({
                                            text: "Quantité",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        children: [
                                            new TextRun({
                                            text: "P.U.H.T",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                      children: [
                                      new TextRun({
                                            text: "Total HT",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                    ],
                })]

        this.ArticlesList.forEach(function(item){

        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                                          children: [
                                        new TextRun({
                                            text: item.ref,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        spacing: {
                                                    before: 100,
                                                    after: 100,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 6000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                                        children: [
                                        new TextRun({
                                            text: item.description,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                                spacing: {
                                                    before: 10,
                                                    after: 10,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 1000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                   
                  children: [
                                        new TextRun({
                                          text: Number(item.tva).toFixed(0).toString() + "%",
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],                                        spacing: {
                                                    before: 10,
                                                    after: 10,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                  children: [
                                            new TextRun({
                                            text: item.qte.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                  children: [
                                        new TextRun({
                                          text: item.prix_ht.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                            children: [
                                        new TextRun({
                                          text: Number(item.total_ht).toFixed(2).toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })

        datrw.push(
        r
        )
        });



        const table = new Table({

            rows:  datrw
        })

        //pied

                let datrw_salare_net =[new TableRow({
                    children: [
                            
                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                 
                                        children: [
                                            new TextRun({
                                              text: "Total HT",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                    spacing: {
                                                    before: 80,
                                                    after: 80
                                                },
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),


                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                                 
                  children: [
                                            new TextRun({
                                            text: rowitem.total_ht.toString() + " DH",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                            ],
                        }),
                        
                        new TableRow({
                    children: [
                            
                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                                                  
                  children: [
                                            new TextRun({
                                            text: "Total TVA",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                    spacing: {
                                                    before: 80,
                                                    after: 80
                                                },
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),


                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                                  
                  children: [
                                            new TextRun({
                                              text: rowitem.total_tva.toString() + " DH",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                            ],
                        }),

                        new TableRow({
                    children: [
                            
                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                                 
                  children: [
                                            new TextRun({
                                            text: "Total TTC",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                    spacing: {
                                                    before: 80,
                                                    after: 80
                                                },
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),


                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                                 children: [
                                            new TextRun({
                                              text: rowitem.total_ttc.toString() + " DH",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                            ],
                        })
                        
                        ]



                const tablenet = new Table({

                    rows:  datrw_salare_net
                })

        //fin pie
      //arret

      let datrw_arret= [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:
                                    "  Arrêtée la présente facture à la somme de : ",
                                  color: "000000",
                                  bold: true,
                                  font: "Segoe UI",
                                }),
                              ],

                              heading: HeadingLevel.HEADING_4,
                              alignment: AlignmentType.LEFT,

                              spacing: {
                                after: 100,
                                before: 100,
                              },
                            }),



                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:" "+total_lettre_entier,
                                  color: "000000",
                                  bold: false,
                                  font: "Segoe UI",
                                }),
                              ],

                              heading: HeadingLevel.HEADING_5,
                              alignment: AlignmentType.LEFT,

                              spacing: {
                                after: 100,
                                before: 100,
                              },
                            }),
              ],
            }),

            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:
                                    " Conditions de règlement : ",
                                  color: "000000",
                                  bold: true,
                                  font: "Segoe UI",
                                }),
                              ],

                              heading: HeadingLevel.HEADING_4,
                              alignment: AlignmentType.LEFT,

                              spacing: {
                                after: 100,
                                before: 100,
                              },
                            }),



                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:"  "+rowitem.mode,
                                  color: "000000",
                                  bold: false,
                                  font: "Segoe UI",
                                }),
                              ],

                              heading: HeadingLevel.HEADING_5,
                              alignment: AlignmentType.LEFT,

                              spacing: {
                                after: 100,
                                before: 100,
                              },
                            }),

              ],
            }),
          ],
        }),

      ];

      const tablearret = new Table({
        rows: datrw_arret,
      });



const doc = new Document({

    sections: [
        {



                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                            margin:{
                                left:500,
                                right:500
                            }
                        },
                    },
  
                    headers: {

                        default: new Header({
                            children: [

                            new Paragraph({
                                    children: [
                                    ],

                                    heading: HeadingLevel.HEADING_5,
                                    alignment: AlignmentType.CENTER,
                                    spacing: {
                                                    after: this.d_logo_e,
                                                    before: 200
                                                },
                                }),



                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [

                        new Paragraph({
                                    children: [

                                    ],

                                    heading: HeadingLevel.HEADING_3,
                                    alignment: AlignmentType.CENTER,
                                    spacing: {
                                                    after: this.d_logo_p,
                                                    before: 200
                                                },
                                }),

                            ],
                        }),
                    },


                    children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "Facture N° : "+rowitem.nom,
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
            ],

            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.CENTER,

            spacing: {
              after: 200,
              before: 200,
            },
          }),

          new Paragraph({
            children: [
              new TextRun({
                text: "Client : ",
                color: "000000",
                bold: true,
                font: "Segoe UI",
              }),
              new TextRun({
                text: rowitem.client,
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
              new TextRun({
                text: "          ICE : ",
                color: "000000",
                bold: true,
                font: "Segoe UI",
              }),

              new TextRun({
                text: rowitem.ice,
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),

                            new TextRun({
                text: "          Date : ",
                color: "000000",
                bold: true,
                font: "Segoe UI",
              }),

              new TextRun({
                text: rowitem.date,
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
            ],



            heading: HeadingLevel.HEADING_3,
            alignment: AlignmentType.CENTER,

            spacing: {
              after: 300,
              before: 100,
            },
          }),
                        table,
                        
                                new Paragraph({
                                    children: [

                                        new TextRun({
                                        text: "",
                                        color: '000000',
                                        bold: false,
                                        font: "Segoe UI"
                                    }),

                                    ],

                                    heading: HeadingLevel.HEADING_2,
                                                        alignment: AlignmentType.CENTER,

                                        spacing: {
                                            after: 50,
                                            before: 50
                                        },
                                }),

                        tablenet,

          new Paragraph({
            children: [
              new TextRun({
                text: "",
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
            ],

            heading: HeadingLevel.HEADING_2,
            alignment: AlignmentType.CENTER,

            spacing: {
              after: 50,
              before: 50,
            },
          }),

            tablearret,
                        
                        ]


        }]

});


            Packer.toBlob(doc).then(blob => {

        saveAs(blob, rowitem.nom+".docx");

            });

        },

        exportDoxLogo(rowitem)
        {

let number_pric= rowitem.total_ttc;

let total_entier = parseInt(number_pric);
let total_decimal = Math.round((number_pric - parseInt(number_pric))*100);


let total_lettre_entier ="";

if(total_decimal>0)
{
 total_lettre_entier = this.NumberToLetter(total_entier) + " Dirhams . " +this.NumberToLetter(total_decimal)+ " Centimes. " ;
}
else
{
 total_lettre_entier = this.NumberToLetter(total_entier) + " Dirhams.";
}



let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        children: [
                                            new TextRun({
                                            text: "Référence",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 6000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                            children: [
                                            new TextRun({
                                            text: "Description",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),




                        new TableCell({
                            width: {
                                size: 1000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                         children: [
                                            new TextRun({
                                            text: "TVA",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        children: [
                                            new TextRun({
                                            text: "Quantité",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        children: [
                                            new TextRun({
                                            text: "P.U.H.T",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                      children: [
                                      new TextRun({
                                            text: "Total HT",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                    ],
                })]

        this.ArticlesList.forEach(function(item){

        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                                          children: [
                                        new TextRun({
                                            text: item.ref,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        spacing: {
                                                    before: 100,
                                                    after: 100,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 6000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                                        children: [
                                        new TextRun({
                                            text: item.description,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                                spacing: {
                                                    before: 10,
                                                    after: 10,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 1000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                   
                  children: [
                                        new TextRun({
                                          text: Number(item.tva).toFixed(0).toString() + "%",
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],                                        spacing: {
                                                    before: 10,
                                                    after: 10,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                  children: [
                                            new TextRun({
                                            text: item.qte.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                  children: [
                                        new TextRun({
                                          text: item.prix_ht.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                            children: [
                                        new TextRun({
                                          text: Number(item.total_ht).toFixed(2).toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })

        datrw.push(
        r
        )
        });



        const table = new Table({

            rows:  datrw
        })

        //pied

                let datrw_salare_net =[new TableRow({
                    children: [
                            
                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                 
                                        children: [
                                            new TextRun({
                                              text: "Total HT",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                    spacing: {
                                                    before: 80,
                                                    after: 80
                                                },
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),


                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                                 
                  children: [
                                            new TextRun({
                                            text: rowitem.total_ht.toString() + " DH",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                            ],
                        }),
                        
                        new TableRow({
                    children: [
                            
                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                                                  
                  children: [
                                            new TextRun({
                                            text: "Total TVA",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                    spacing: {
                                                    before: 80,
                                                    after: 80
                                                },
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),


                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                                  
                  children: [
                                            new TextRun({
                                              text: rowitem.total_tva.toString() + " DH",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                            ],
                        }),

                        new TableRow({
                    children: [
                            
                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                                 
                  children: [
                                            new TextRun({
                                            text: "Total TTC",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                    spacing: {
                                                    before: 80,
                                                    after: 80
                                                },
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),


                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                                 children: [
                                            new TextRun({
                                              text: rowitem.total_ttc.toString() + " DH",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                            ],
                        })
                        
                        ]



                const tablenet = new Table({

                    rows:  datrw_salare_net
                })

        //fin pie
      //arret

      let datrw_arret= [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:
                                    "  Arrêtée la présente facture à la somme de : ",
                                  color: "000000",
                                  bold: true,
                                  font: "Segoe UI",
                                }),
                              ],

                              heading: HeadingLevel.HEADING_4,
                              alignment: AlignmentType.LEFT,

                              spacing: {
                                after: 100,
                                before: 100,
                              },
                            }),



                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:" "+total_lettre_entier,
                                  color: "000000",
                                  bold: false,
                                  font: "Segoe UI",
                                }),
                              ],

                              heading: HeadingLevel.HEADING_5,
                              alignment: AlignmentType.LEFT,

                              spacing: {
                                after: 100,
                                before: 100,
                              },
                            }),
              ],
            }),

            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:
                                    " Conditions de règlement : ",
                                  color: "000000",
                                  bold: true,
                                  font: "Segoe UI",
                                }),
                              ],

                              heading: HeadingLevel.HEADING_4,
                              alignment: AlignmentType.LEFT,

                              spacing: {
                                after: 100,
                                before: 100,
                              },
                            }),



                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:"  "+rowitem.mode,
                                  color: "000000",
                                  bold: false,
                                  font: "Segoe UI",
                                }),
                              ],

                              heading: HeadingLevel.HEADING_5,
                              alignment: AlignmentType.LEFT,

                              spacing: {
                                after: 100,
                                before: 100,
                              },
                            }),

              ],
            }),
          ],
        }),

      ];

      const tablearret = new Table({
        rows: datrw_arret,
      });


const doc = new Document({

    sections: [
        {



                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                            margin:{
                                left:500,
                                right:500
                            }
                        },
                    },
                    headers: {

                        default: new Header({
                            children: [




                    new Paragraph({
                    children: [


                        new ImageRun({
                            
                        data: Uint8Array.from(atob(this.d_logo_img), c =>
                            c.charCodeAt(0)
                        ),
                        transformation: {
                            width: this.d_logo_w,
                            height: this.d_logo_h
                        },



                        })
                    ]
                    }),

                            /* new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.sousentet_pag,
                                color: '000000',
                                bold: false,
                                })

                                    ],
                                border: {
                                bottom: {
                                        color: "000000",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_3,
                                    alignment: AlignmentType.CENTER,
                                }),*/

                            new Paragraph({
                                    children: [
                                    ],
                                border: {
                                bottom: {
                                        color: "000000",
                                        space: 1,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_5,
                                    alignment: AlignmentType.CENTER,
                                }),



                            ],
                        }),
                    },
                            footers: {
                                default: new Footer({
                                    children: [

                                new Paragraph({
                                            children: [
                                    new TextRun({
                                        text: this.pied_pag,
                                        color: '000000',
                                        bold: false,
                                        })

                                            ],
                                        border: {
                                        top: {
                                                color: "000000",
                                                space: 4,
                                                value: "single",
                                                size: 10,
                                            }},
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        }),

                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    children: ["Page : ", PageNumber.CURRENT],
                                                    color: '000000'
                                                }),
                                                new TextRun({
                                                    children: [" Sur ", PageNumber.TOTAL_PAGES],
                                                    color: '000000'
                                                }),
                                            ],
                                        }),
                                    ],
                                }),
                            },



                            children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "Facture N° : "+rowitem.nom,
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
            ],

            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.CENTER,

            spacing: {
              after: 200,
              before: 200,
            },
          }),

          new Paragraph({
            children: [
              new TextRun({
                text: "Client : ",
                color: "000000",
                bold: true,
                font: "Segoe UI",
              }),
              new TextRun({
                text: rowitem.client,
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
              new TextRun({
                text: "          ICE : ",
                color: "000000",
                bold: true,
                font: "Segoe UI",
              }),

              new TextRun({
                text: rowitem.ice,
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),

                            new TextRun({
                text: "          Date : ",
                color: "000000",
                bold: true,
                font: "Segoe UI",
              }),

              new TextRun({
                text: rowitem.date,
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
            ],



            heading: HeadingLevel.HEADING_3,
            alignment: AlignmentType.CENTER,

            spacing: {
              after: 300,
              before: 100,
            },
          }),
                        table,
                        
                                new Paragraph({
                                    children: [

                                        new TextRun({
                                        text: "",
                                        color: '000000',
                                        bold: false,
                                        font: "Segoe UI"
                                    }),

                                    ],

                                    heading: HeadingLevel.HEADING_2,
                                                        alignment: AlignmentType.CENTER,

                                        spacing: {
                                            after: 50,
                                            before: 50
                                        },
                                }),

                        tablenet,

          new Paragraph({
            children: [
              new TextRun({
                text: "",
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
            ],

            heading: HeadingLevel.HEADING_2,
            alignment: AlignmentType.CENTER,

            spacing: {
              after: 50,
              before: 50,
            },
          }),

            tablearret,
                        
                        ]


        }]

});


            Packer.toBlob(doc).then(blob => {

        saveAs(blob, rowitem.nom+".docx");

            });

        },
        exportDox(rowitem)
        {

let number_pric= rowitem.total_ttc;

let total_entier = parseInt(number_pric);
let total_decimal = Math.round((number_pric - parseInt(number_pric))*100);


let total_lettre_entier ="";

if(total_decimal>0)
{
 total_lettre_entier = this.NumberToLetter(total_entier) + " Dirhams . " +this.NumberToLetter(total_decimal)+ " Centimes. " ;
}
else
{
 total_lettre_entier = this.NumberToLetter(total_entier) + " Dirhams.";
}




        let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        children: [
                                            new TextRun({
                                            text: "Référence",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 6000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                            children: [
                                            new TextRun({
                                            text: "Description",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),




                        new TableCell({
                            width: {
                                size: 1000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                         children: [
                                            new TextRun({
                                            text: "TVA",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        children: [
                                            new TextRun({
                                            text: "Quantité",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        children: [
                                            new TextRun({
                                            text: "P.U.H.T",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                      children: [
                                      new TextRun({
                                            text: "Total HT",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                    ],
                })]

        this.ArticlesList.forEach(function(item){

        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                                          children: [
                                        new TextRun({
                                            text: item.ref,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        spacing: {
                                                    before: 100,
                                                    after: 100,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 6000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                                        children: [
                                        new TextRun({
                                            text: item.description,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                                spacing: {
                                                    before: 10,
                                                    after: 10,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 1000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                   
                  children: [
                                        new TextRun({
                                          text: Number(item.tva).toFixed(0).toString() + "%",
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],                                        spacing: {
                                                    before: 10,
                                                    after: 10,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                  children: [
                                            new TextRun({
                                            text: item.qte.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                  children: [
                                        new TextRun({
                                          text: item.prix_ht.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                            children: [
                                        new TextRun({
                                          text: Number(item.total_ht).toFixed(2).toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })

        datrw.push(
        r
        )
        });



        const table = new Table({

            rows:  datrw
        })

        //pied

                let datrw_salare_net =[new TableRow({
                    children: [
                            
                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                 
                                        children: [
                                            new TextRun({
                                              text: "Total HT",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                    spacing: {
                                                    before: 80,
                                                    after: 80
                                                },
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),


                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                                 
                  children: [
                                            new TextRun({
                                            text: rowitem.total_ht.toString() + " DH",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                            ],
                        }),
                        
                        new TableRow({
                    children: [
                            
                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                                                  
                  children: [
                                            new TextRun({
                                            text: "Total TVA",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                    spacing: {
                                                    before: 80,
                                                    after: 80
                                                },
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),


                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                                  
                  children: [
                                            new TextRun({
                                              text: rowitem.total_tva.toString() + " DH",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                            ],
                        }),

                        new TableRow({
                    children: [
                            
                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                                 
                  children: [
                                            new TextRun({
                                            text: "Total TTC",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                    spacing: {
                                                    before: 80,
                                                    after: 80
                                                },
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),


                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                                 children: [
                                            new TextRun({
                                              text: rowitem.total_ttc.toString() + " DH",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                            ],
                        })
                        
                        ]



                const tablenet = new Table({

                    rows:  datrw_salare_net
                })

        //fin pie
      //arret

      let datrw_arret= [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:
                                    "  Arrêtée la présente facture à la somme de : ",
                                  color: "000000",
                                  bold: true,
                                  font: "Segoe UI",
                                }),
                              ],

                              heading: HeadingLevel.HEADING_4,
                              alignment: AlignmentType.LEFT,

                              spacing: {
                                after: 100,
                                before: 100,
                              },
                            }),



                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:" "+total_lettre_entier,
                                  color: "000000",
                                  bold: false,
                                  font: "Segoe UI",
                                }),
                              ],

                              heading: HeadingLevel.HEADING_5,
                              alignment: AlignmentType.LEFT,

                              spacing: {
                                after: 100,
                                before: 100,
                              },
                            }),
              ],
            }),

            new TableCell({
              width: {
                size: 6250,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:
                                    " Conditions de règlement : ",
                                  color: "000000",
                                  bold: true,
                                  font: "Segoe UI",
                                }),
                              ],

                              heading: HeadingLevel.HEADING_4,
                              alignment: AlignmentType.LEFT,

                              spacing: {
                                after: 100,
                                before: 100,
                              },
                            }),



                            new Paragraph({
                              children: [
                                new TextRun({
                                  text:"  "+rowitem.mode,
                                  color: "000000",
                                  bold: false,
                                  font: "Segoe UI",
                                }),
                              ],

                              heading: HeadingLevel.HEADING_5,
                              alignment: AlignmentType.LEFT,

                              spacing: {
                                after: 100,
                                before: 100,
                              },
                            }),

              ],
            }),
          ],
        }),

      ];

      const tablearret = new Table({
        rows: datrw_arret,
      });

const doc = new Document({

    sections: [
        {



                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                            margin:{
                                left:500,
                                right:500
                            }
                        },
                    },
                            headers: {
                                default: new Header({
                                    children: [
                                new Paragraph({
                                            children: [
                                    new TextRun({
                                        text: this.entet_pag,
                                        color: '000000',
                                        bold: true,

                                        }),
                                            ],
                                            heading: HeadingLevel.TITLE,
                                            alignment: AlignmentType.CENTER,
                                        }),

                                new Paragraph({
                                            children: [
                                    new TextRun({
                                        text: this.sousentet_pag,
                                        color: '000000',
                                        bold: false,
                                        })

                                            ],
                                        border: {
                                        bottom: {
                                                color: "000000",
                                                space: 4,
                                                value: "single",
                                                size: 10,
                                            }},
                                            heading: HeadingLevel.HEADING_1,
                                            alignment: AlignmentType.CENTER,
                                        }),




                                    ],
                                }),
                            },
                            footers: {
                                default: new Footer({
                                    children: [

                                new Paragraph({
                                            children: [
                                    new TextRun({
                                        text: this.pied_pag,
                                        color: '000000',
                                        bold: false,
                                        })

                                            ],
                                        border: {
                                        top: {
                                                color: "000000",
                                                space: 4,
                                                value: "single",
                                                size: 10,
                                            }},
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        }),

                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    children: ["Page : ", PageNumber.CURRENT],
                                                    color: '000000'
                                                }),
                                                new TextRun({
                                                    children: [" Sur ", PageNumber.TOTAL_PAGES],
                                                    color: '000000'
                                                }),
                                            ],
                                        }),
                                    ],
                                }),
                            },


                    children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "Facture N° : "+rowitem.nom,
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
            ],

            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.CENTER,

            spacing: {
              after: 200,
              before: 200,
            },
          }),

          new Paragraph({
            children: [
              new TextRun({
                text: "Client : ",
                color: "000000",
                bold: true,
                font: "Segoe UI",
              }),
              new TextRun({
                text: rowitem.client,
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
              new TextRun({
                text: "          ICE : ",
                color: "000000",
                bold: true,
                font: "Segoe UI",
              }),

              new TextRun({
                text: rowitem.ice,
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),

                            new TextRun({
                text: "          Date : ",
                color: "000000",
                bold: true,
                font: "Segoe UI",
              }),

              new TextRun({
                text: rowitem.date,
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
            ],



            heading: HeadingLevel.HEADING_3,
            alignment: AlignmentType.CENTER,

            spacing: {
              after: 300,
              before: 100,
            },
          }),
                        table,
                        
                                new Paragraph({
                                    children: [

                                        new TextRun({
                                        text: "",
                                        color: '000000',
                                        bold: false,
                                        font: "Segoe UI"
                                    }),

                                    ],

                                    heading: HeadingLevel.HEADING_2,
                                                        alignment: AlignmentType.CENTER,

                                        spacing: {
                                            after: 50,
                                            before: 50
                                        },
                                }),

                        tablenet,

          new Paragraph({
            children: [
              new TextRun({
                text: "",
                color: "000000",
                bold: false,
                font: "Segoe UI",
              }),
            ],

            heading: HeadingLevel.HEADING_2,
            alignment: AlignmentType.CENTER,

            spacing: {
              after: 50,
              before: 50,
            },
          }),

            tablearret,
                        
                        ]

        }]

});


            Packer.toBlob(doc).then(blob => {

        saveAs(blob, rowitem.nom+".docx");

            });

        },


        edit_click(item) {

                this.$confirm("Voulez-vous vraiment Devalider et modifier ce Bon ??")
                 .then(() => {

                    this.DevaliderBon(item.id,true)

                })
                .catch(error=> {
                console.log(error)

                });


        },
            devald_click(item) {

                                    this.$confirm("Voulez-vous vraiment Devalider ce Bon??")
                                    .then(() => {

                                        this.DevaliderBon(item.id,false)
                                    })
                                    .catch(error=> {
                                    console.log(error)

                                        });




        },
        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/factureClientType/1/0/'+this.user_dep)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        DevaliderBon(id_bn,edit)
        {


  
                    //procedure du modification
                                this.isLoading=true;

                                this.$http.post('/validFactureClRegl',{
                                            date:this.dateToday,  
                                            user:this.user_name,
                                            bank_compte_id:0,
                                            id_bon:id_bn,
                                            type:0,
                                            regl:0
                                    })
                                    .then(response => {

                                        this.isLoading=false;
                                        console.log(response.data)

                                            this.flashMessage.show({
                                            status: 'success',
                                            title: 'Bon Devalidé',
                                            message: 'Les données sont devalidées correctement'
                                            })

                                                if(edit)
                                                {
                                                                        this.$router.push({ 
                                                                        name: 'factureClient', 
                                                                        params: { 
                                                                        ResivedId: id_bn
                                                                        } 
                                                                        });
                                                }
                                                else
                                                {
                                                                let index = this.itemslist.findIndex(item => item.id === id_bn)
                                                                this.itemslist.splice(index, 1)

                                                }




                                        })
                                        .catch(error=> {console.log(error)
                                        this.isLoading=false;


                                            this.flashMessage.show({
                                            status: 'error',
                                            title: 'Erreur De Suppression',
                                            message: 'Impossible  de devalider les données .. Réessayez !!'
                                            })

                                    });

        },

        fetchListArts(row_bn)
        {
                    this.isLoading=true;
                    this.$http.get('/detailFactureClient/'+row_bn.id)
                    .then((result) => {
                                this.ArticlesList = result.data.data
                                this.isLoading=false;


                                this.curentBN=row_bn.nom + " ( "+row_bn.date+" )"
                                this.$root.$emit('bv::show::modal', 'detailModal')

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },
        NewBon()
        {
                    this.$router.push({ 
                        name: 'menuFacture'
                    });
        },

            Unite(nombre) {
      var unite;
      switch (nombre) {
        case 0:
          unite = "zéro";
          break;
        case 1:
          unite = "un";
          break;
        case 2:
          unite = "deux";
          break;
        case 3:
          unite = "trois";
          break;
        case 4:
          unite = "quatre";
          break;
        case 5:
          unite = "cinq";
          break;
        case 6:
          unite = "six";
          break;
        case 7:
          unite = "sept";
          break;
        case 8:
          unite = "huit";
          break;
        case 9:
          unite = "neuf";
          break;
      } //fin switch
      return unite;
    },

    Dizaine(nombre) {
         var dizaine;
      switch (nombre) {
        case 10:
          dizaine = "dix";
          break;
        case 11:
          dizaine = "onze";
          break;
        case 12:
          dizaine = "douze";
          break;
        case 13:
          dizaine = "treize";
          break;
        case 14:
          dizaine = "quatorze";
          break;
        case 15:
          dizaine = "quinze";
          break;
        case 16:
          dizaine = "seize";
          break;
        case 17:
          dizaine = "dix-sept";
          break;
        case 18:
          dizaine = "dix-huit";
          break;
        case 19:
          dizaine = "dix-neuf";
          break;
        case 20:
          dizaine = "vingt";
          break;
        case 30:
          dizaine = "trente";
          break;
        case 40:
          dizaine = "quarante";
          break;
        case 50:
          dizaine = "cinquante";
          break;
        case 60:
          dizaine = "soixante";
          break;
        case 70:
          dizaine = "soixante-dix";
          break;
        case 80:
          dizaine = "quatre-vingt";
          break;
        case 90:
          dizaine = "quatre-vingt-dix";
          break;
      } //fin switch
      return dizaine;
    },
    NumberToLetter(nombre) {
      var  quotient, reste, nb,n;
      var numberToLetter = "";
      //__________________________________

      if (nombre.toString().replace(/ /gi, "").length > 15)
        return "dépassement de capacité";
      if (isNaN(nombre.toString().replace(/ /gi, "")))
        return "Nombre non valide";

      nb = parseFloat(nombre.toString().replace(/ /gi, ""));
      if (Math.ceil(nb) != nb) return "Nombre avec virgule non géré.";

      n = nb.toString().length;
      switch (n) {
        case 1:
          numberToLetter = this.Unite(nb);
          break;
        case 2:
          if (nb > 19) {
            quotient = Math.floor(nb / 10);
            reste = nb % 10;
            if (nb < 71 || (nb > 79 && nb < 91)) {
              if (reste == 0) numberToLetter = this.Dizaine(quotient * 10);
              if (reste == 1)
                numberToLetter = this.Dizaine(quotient * 10) + "-et-" + this.Unite(reste);
              if (reste > 1)
                numberToLetter = this.Dizaine(quotient * 10) + "-" + this.Unite(reste);
            } else
              numberToLetter =
                this.Dizaine((quotient - 1) * 10) + "-" + this.Dizaine(10 + reste);
          } else numberToLetter = this.Dizaine(nb);
          break;
        case 3:
          quotient = Math.floor(nb / 100);
          reste = nb % 100;
          if (quotient == 1 && reste == 0) numberToLetter = "cent";
          if (quotient == 1 && reste != 0)
            numberToLetter = "cent" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.Unite(quotient) + " cents";
          if (quotient > 1 && reste != 0)
            numberToLetter = this.Unite(quotient) + " cent " + this.NumberToLetter(reste);
          break;
        case 4:
          quotient = Math.floor(nb / 1000);
          reste = nb - quotient * 1000;
          if (quotient == 1 && reste == 0) numberToLetter = "mille";
          if (quotient == 1 && reste != 0)
            numberToLetter = "mille" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " mille";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " mille " + this.NumberToLetter(reste);
          break;
        case 5:
          quotient = Math.floor(nb / 1000);
          reste = nb - quotient * 1000;
          if (quotient == 1 && reste == 0) numberToLetter = "mille";
          if (quotient == 1 && reste != 0)
            numberToLetter = "mille" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " mille";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " mille " + this.NumberToLetter(reste);
          break;
        case 6:
          quotient = Math.floor(nb / 1000);
          reste = nb - quotient * 1000;
          if (quotient == 1 && reste == 0) numberToLetter = "mille";
          if (quotient == 1 && reste != 0)
            numberToLetter = "mille" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " mille";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " mille " + this.NumberToLetter(reste);
          break;
        case 7:
          quotient = Math.floor(nb / 1000000);
          reste = nb % 1000000;
          if (quotient == 1 && reste == 0) numberToLetter = "un million";
          if (quotient == 1 && reste != 0)
            numberToLetter = "un million" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " millions";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " millions " + this.NumberToLetter(reste);
          break;
        case 8:
          quotient = Math.floor(nb / 1000000);
          reste = nb % 1000000;
          if (quotient == 1 && reste == 0) numberToLetter = "un million";
          if (quotient == 1 && reste != 0)
            numberToLetter = "un million" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " millions";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " millions " + this.NumberToLetter(reste);
          break;
        case 9:
          quotient = Math.floor(nb / 1000000);
          reste = nb % 1000000;
          if (quotient == 1 && reste == 0) numberToLetter = "un million";
          if (quotient == 1 && reste != 0)
            numberToLetter = "un million" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " millions";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " millions " + this.NumberToLetter(reste);
          break;
        case 10:
          quotient = Math.floor(nb / 1000000000);
          reste = nb - quotient * 1000000000;
          if (quotient == 1 && reste == 0) numberToLetter = "un milliard";
          if (quotient == 1 && reste != 0)
            numberToLetter = "un milliard" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " milliards";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " milliards " + this.NumberToLetter(reste);
          break;
        case 11:
          quotient = Math.floor(nb / 1000000000);
          reste = nb - quotient * 1000000000;
          if (quotient == 1 && reste == 0) numberToLetter = "un milliard";
          if (quotient == 1 && reste != 0)
            numberToLetter = "un milliard" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " milliards";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " milliards " + this.NumberToLetter(reste);
          break;
        case 12:
          quotient = Math.floor(nb / 1000000000);
          reste = nb - quotient * 1000000000;
          if (quotient == 1 && reste == 0) numberToLetter = "un milliard";
          if (quotient == 1 && reste != 0)
            numberToLetter = "un milliard" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " milliards";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " milliards " + this.NumberToLetter(reste);
          break;
        case 13:
          quotient = Math.floor(nb / 1000000000000);
          reste = nb - quotient * 1000000000000;
          if (quotient == 1 && reste == 0) numberToLetter = "un billion";
          if (quotient == 1 && reste != 0)
            numberToLetter = "un billion" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " billions";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " billions " + this.NumberToLetter(reste);
          break;
        case 14:
          quotient = Math.floor(nb / 1000000000000);
          reste = nb - quotient * 1000000000000;
          if (quotient == 1 && reste == 0) numberToLetter = "un billion";
          if (quotient == 1 && reste != 0)
            numberToLetter = "un billion" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " billions";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " billions " + this.NumberToLetter(reste);
          break;
        case 15:
          quotient = Math.floor(nb / 1000000000000);
          reste = nb - quotient * 1000000000000;
          if (quotient == 1 && reste == 0) numberToLetter = "un billion";
          if (quotient == 1 && reste != 0)
            numberToLetter = "un billion" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste == 0)
            numberToLetter = this.NumberToLetter(quotient) + " billions";
          if (quotient > 1 && reste != 0)
            numberToLetter =
              this.NumberToLetter(quotient) + " billions " + this.NumberToLetter(reste);
          break;
      } //fin switch
      /*respect de l'accord de quatre-vingt*/
      if (
        numberToLetter.substr(
          numberToLetter.length - "quatre-vingt".length,
          "quatre-vingt".length
        ) == "quatre-vingt"
      )
        numberToLetter = numberToLetter + "s";

      return numberToLetter;
    }, //-----------------------------------------------------------------------
    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>
.var_color{
  color: #2faaa5;
}
</style>
<template>
<div>
  

    <!-- le modal -->
    <b-modal id="clientsModal" :title="itemForm.nom_prenom" size="lg" hide-footer>
           <form @submit.prevent="handleSubmit">
            <div class="row">
            <div class="form-group col">
                <label for="code">Code Client :</label>
                <input type="text" v-model="itemForm.code" id="code" name="code" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.code.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.code.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>


            <div class="form-group col">
                <label for="cin">CIN :</label>
                <input type="text" v-model="itemForm.cin" id="name" name="cin" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.cin.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.cin.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>
        </div>
        <div class="row">



            <div class="form-group col">
                <label for="nom_prenom">Nom et Prénom :</label>
                <input type="text" v-model="itemForm.nom_prenom" id="name" name="nom_prenom" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.nom_prenom.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.nom_prenom.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>

            <div class="form-group col">

                            <label for="itemForm">Zone :</label>
                            <select class="form-control" id="itemForm" v-model="itemForm.zone_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.zone_id.$error }">
                            <option v-for="item in zones" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>

            </div>
                </div>

                <div class="row">


                <div class="form-group col">
                    <label for="tel">Profession :</label>
                    <input type="text" v-model="itemForm.profession" id="tel" name="tel" class="form-control" />

                </div>

                <div class="form-group col">
                    <label for="tel">Téléphone :</label>
                    <input type="text" v-model="itemForm.tel" id="tel" name="tel" class="form-control" />

                </div>


                </div>

            <div class="row">

                        <div class="form-group col">
                            <label for="ice">ICE :</label>
                            <input type="text" v-model="itemForm.ice" id="ice" name="ice" class="form-control" />
                        </div>

                        
                        <div class="form-group col">
                            <label for="garant">Garant(e) :</label>
                            <input type="text" v-model="itemForm.garant" id="garant" name="garant" class="form-control" />

                        </div>
            </div>



            <div class="row">

                <div class="form-group col">
                <label for="Description">Adresse :</label>
                <textarea name="description" id="description"  rows="2" class="form-control" v-model="itemForm.adresse"></textarea>
                </div>


                <div class="form-group col">
                <label for="garant_infos">Garant(e) Infos:</label>
                <textarea name="garant_infos" id="garant_infos"  rows="2" class="form-control" v-model="itemForm.garant_infos"></textarea>
                </div>

            </div>




            <div class="row">
                <div class="form-group col">
                <div class="custom-control custom-switch">
                <input type="checkbox"
                 class="custom-control-input" 
                 id="is_plaf"
                        v-model="itemForm.is_plaf"
                        name="is_plaf"
                        value=true
                        unchecked-value=false
    
                 >
    
                <label class="custom-control-label" for="is_plaf">Activer Le Plafond</label>
                </div>
                </div>
    
                <div v-if="itemForm.is_plaf" class="form-group col">
                    <label for="plafond">Plafond</label>
                    <input type="text" v-model="itemForm.plafond" id="plafond" name="plafond" class="form-control"
                        :class="{ 'is-invalid': isSubmitted && $v.itemForm.plafond.$error }" />
                    <div v-if="isSubmitted && !$v.itemForm.plafond" class="invalid-feedback">Ce champ est obligatoire !!</div>
                    <div v-if="isSubmitted && !$v.itemForm.plafond" class="invalid-feedback">Entrez une valeur numérique !!</div>
                </div>
            </div>
    

            <div class="custom-control custom-switch">
            <input type="checkbox"
             class="custom-control-input" 
             id="etat"
                    v-model="itemForm.etat"
                    name="etat"
                    value=true
                    unchecked-value=false

             >

            <label class="custom-control-label" for="etat">Actif</label>
            </div>

            <div class="form-group mt-3">
                <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
            </div>

        </form>
    </b-modal>


    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";


export default {
    computed: {

    },
    mounted(){

        this.fetchFormZones()

        this.itemForm.id=''
        this.itemForm.code='--'
        this.itemForm.cin='--'
        this.itemForm.nom_prenom=''
        this.itemForm.tel=''
        this.itemForm.adresse=''
        this.itemForm.ice=''
        this.itemForm.etat=true
        this.itemForm.profession=''
        this.itemForm.garant=''
        this.itemForm.garant_infos=''
        this.itemForm.is_plaf=false
        this.itemForm.plafond=0
        this.ModalEdit=false;
        this.ModalType='Ajouter'

    },
	data () {
		return {

            selected_zone:'',
            zones:[],
            itemForm: {
                    id:'',
                    code:'',
                    cin:'',
                    nom_prenom:'',
                    tel:'',
                    etat:true,
                    adresse:'',
                    ice:'',
                    profession:'',
                    garant:'',
                    garant_infos:'',
                    is_plaf:false,
                    plafond:0,
                    zone_id:''},

            //public trucs
            ModalEdit: false,
            ModalType:'Ajouter',
            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemForm: {
                code: {
                    required
                },
                cin: {
                    required
                },
               nom_prenom: {
                    required
                },
                zone_id: {
                    required
                },
                is_plaf: {
                    required
                },
                plafond: {
                    required,
                    decimal
                },
                etat: {
                    required
                }
            }
    },
    methods :{


        handleSubmit() {
                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {



                                this.itemslisttore()
          

                }

            },

        itemslisttore(){
                        this.isLoading=true;

            this.$http.post('/clients',{
                code:this.itemForm.code,
            cin:this.itemForm.cin,
            nom_prenom:this.itemForm.nom_prenom,
            tel:this.itemForm.tel,
            adresse:this.itemForm.adresse,
            ice:this.itemForm.ice,
            etat:this.itemForm.etat,
            profession:this.itemForm.profession,
            garant:this.itemForm.garant,
            garant_infos:this.itemForm.garant_infos,
            is_plaf:this.itemForm.is_plaf,
            plafond:this.itemForm.plafond,
            zone_id:this.itemForm.zone_id


                })
                .then(response => {

                    this.isLoading=false;
                    //this.itemslist.unshift(response.data.data);
                    console.log(response.data.data)

                    this.$emit('reload-event')

                    this.$bvModal.hide('clientsModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
            fetchFormZones: function () {
                    //chager les categories:
                    this.isLoading=true;
                    this.$http.get('/zones')
                    .then((result) => {
                                this.zones = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
           }

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>
<template>
<div>
  
                        

      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    

            <h4 class="col ml-3 font-weight-lighter var_color"> <span class="fa fa-file-signature"></span> Devis (Archivés)</h4> 

                    <div class="col">
         

                                    <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                                                <i class="fa fa-home fa-2x"></i>
                                    </b-button>

                                    <b-button pill size="sm"  class="m-2 float-right" variant="info" @click="fetchList">
                                        <i class="fa fa-sync-alt"></i>
                                        <span> Actualiser</span> 
                                    </b-button>
                    </div>
            </div>
       



        <b-table 
        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
            <template #cell(actions)="row">


                    <b-button  pill size="sm"  class="mr-2  mt-1 mb-1 float-right" variant="outline-success" @click="crer_facture_click(row.item)">
                                <i class="fa fa-paste"></i>
                    </b-button>

                    <b-button  pill size="sm"  class="mr-2  mt-1 mb-1 float-right" variant="outline-primary" @click="crer_bl_click(row.item)">
                                <i class="fa fa-clipboard"></i>
                    </b-button>

                    <b-button pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                    </b-button>


                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-warning" @click="desarchiver_click(row.item)">
                                <i class="fa fa-check"></i>
                    </b-button>

                    <b-button pill size="sm" class="mr-2 float-right" variant="outline-primary" @click="edit_click(row.item)">
                            <i class="fa fa-pencil-alt"></i>
                    </b-button>

                    <b-button pill size="sm" class="mr-2 float-right" variant="outline-info" @click="fetchListArts(row.item)">
                            <i class="fa fa-bars"></i>
                    </b-button>

                </template>
        
        </b-table>

                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>

      </div>


    <!-- le modal -->
    <b-modal id="detailModal" :title=curentBN size="lg" hide-footer>


       <b-table 
        small 
        borderless
        striped 
        sticky-header
        :fields="fieldsArticle" 
        :items="ArticlesList"
        >

        
        </b-table>
              <div class="card border-info">
                        <div class=" d-flex justify-content-center pt-1 text-info">
                                <h5 class="font-weight-light ">Total Prix : {{total_price}} </h5>
                        </div>
              </div>
    </b-modal>

    <!-- les trucs public -->

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";

export default {
      computed: {
        user_name() {

        if(this.$store.getters.get_user)
        {
                return this.$store.getters.get_user.name;
        }
        else
        {
                return '';
        }
        },
      rows_pagin() {
        return this.itemslist.length
      },
      total_price: function() {
      return this.ArticlesList.reduce((a, b) => +a + +(b.qte * b.prix), 0).toFixed(2);
        }
      },
    mounted(){
        this.fetchList()
    },
	data () {
		return {
            perPage: 10,
            currentPage: 1,
            itemslist: [],

            
            fieldsArticle: [
                {key: 'ref', label: 'Réf'},
                {key: 'qte', label: 'Quantité'},
                 {key: 'unite', label: 'Unité'},
                {key: 'prix', label: 'Prix'},
                {key: 'description', label: 'Description'}
            ],
            ArticlesList:[],

            curentBN:'',

            fields: [
				{key: 'nom', label: 'Nom', sortable: true},
                {key: 'date', label: 'Date', sortable: true},
                {key: 'valider', label: 'Archiver', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }, sortable: true},
                {key: 'description', label: 'Description', sortable: true},
                 {key: 'user', label: 'Utilisateur', sortable: true},
                { key: 'actions', label: '' }
            ],
            //public trucs

            isLoading: false
		}
	},
    validations: {
            itemForm: {
                nom: {
                    required
                }
            }
    },
    methods :{




            crer_bl_click(item) {

                                    this.$confirm("Voulez-vous vraiment Créer le BL de ce Devis??")
                                    .then(() => {

                                      



                            this.isLoading=true;

                            this.$http.post('/blFromDevis',{
                            date:item.date,
                            user:this.user_name,
                            id_bon:item.id,


                                })
                                .then(response => {

                                    this.isLoading=false;


                                    //desactiver la validation:
                                    this.isSubmitted=false
                                    //afficher message
                                    this.flashMessage.show({
                                    status: 'success',
                                    title: 'Données sauvegardées',
                                    message: 'Les données sont sauvegardées correctement '
                                    })

                                                                        this.$router.push({ 
                                                                        name: 'blClients', 
                                                                        params: { 
                                                                        ResivedId: response.data.data
                                                                        } 
                                                                        });

                                })
                                .catch(error=> {console.log(error)
                                this.isLoading=false;


                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur De Sauvegarde',
                                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                })
                                });

                                })
                                    .catch(error=> {
                                    console.log(error)

                                });




        },



            crer_facture_click(item) {

                                    this.$confirm("Voulez-vous vraiment Créer la facture de ce Devis??")
                                    .then(() => {

                                      



                            this.isLoading=true;

                            this.$http.post('/factureFromDevis',{
                            date:item.date,
                            user:this.user_name,
                            id_bon:item.id,


                                })
                                .then(response => {

                                    this.isLoading=false;


                                    //desactiver la validation:
                                    this.isSubmitted=false
                                    //afficher message
                                    this.flashMessage.show({
                                    status: 'success',
                                    title: 'Données sauvegardées',
                                    message: 'Les données sont sauvegardées correctement '
                                    })

                                                                        this.$router.push({ 
                                                                        name: 'factureClient', 
                                                                        params: { 
                                                                        ResivedId: response.data.data
                                                                        } 
                                                                        });



                                })
                                .catch(error=> {console.log(error)
                                this.isLoading=false;


                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur De Sauvegarde',
                                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                })
                                });

                                })
                                    .catch(error=> {
                                    console.log(error)

                                });




        },




        fetchListArts(row_bn)
        {
            //detailAvoirFour
                    this.isLoading=true;
                    this.$http.get('/detailDevisClient/'+row_bn.id)
                    .then((result) => {
                                this.ArticlesList = result.data.data
                                this.isLoading=false;


                                this.curentBN=row_bn.nom + " ( "+row_bn.date+" )"
                                this.$root.$emit('bv::show::modal', 'detailModal')

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },

        desarchiver_click(item){

                                this.$confirm("Voulez-vous vraiment Désarchiver ce Bon??")
                                .then(() => {

                                    this.BnDesarchiver(item.id,false)
                                })
                                .catch(error=> {
                                console.log(error)

                                    });
        },
              BnDesarchiver(id_bn,edit){

            this.isLoading=true;

            this.$http.get('/devisValidClient/'+id_bn+'/0')
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Bon Desarchivé',
                message: 'Les données sont desarchiver correctement'
                })

                    if(edit)
                    {
                                            this.$router.push({ 
                                            name: 'bnDevis', 
                                            params: { 
                                            ResivedId: id_bn
                                            } 
                                            });
                    }
                    else
                    {
                                    let index = this.itemslist.findIndex(item => item.id === id_bn)
                                    this.itemslist.splice(index, 1)

                    }




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de devalider les données .. Réessayez !!'
                })
                });

        },
        edit_click(item) {

                this.$confirm("Voulez-vous vraiment desarchiver et modifier ce Bon ??")
                .then(() => {

                    this.BnDesarchiver(item.id,true)

                })
                .catch(error=> {
                console.log(error)

                });


      },
        del_click(item) {

                                this.$confirm("Voulez-vous vraiment supprimer ce Bon??")
                                .then(() => {

                                    this.BnDell(item.id)
                                })
                                .catch(error=> {
                                console.log(error)

                                    });




      },
        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/devisType/1')
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        BnDell(id_bn){

            this.isLoading=true;

            this.$http.delete('/devisClient/'+id_bn)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.itemslist.findIndex(item => item.id === id_bn)
                    this.itemslist.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        NewBon()
        {
                    this.$router.push({ 
                        name: 'menuDevis'
                    });
        },

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>
.var_color{
  color: #2faaa5;
}
</style>
<template>
<div>
  
                        

      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    


            <h4 class="col ml-3 font-weight-lighter var_color"> <span class="fa fa-file-prescription"></span> Bons De Retour Clients ( Reglés )    </h4> 

                    <div class="col">

                                    <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                                                <i class="fa fa-home fa-2x"></i>
                                    </b-button>

                                    <b-button pill size="sm"  class="m-2 float-right" variant="info" @click="fetchList">
                                        <i class="fa fa-sync-alt"></i>
                                        <span> Actualiser</span> 
                                    </b-button>
                    </div>
            </div>
       



        <b-table 
        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"
        responsive  
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
            <template #cell(actions)="row">
                    <b-button v-if="d_gst_br_client_val==true" pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-warning" @click="devald_click(row.item)">
                                <i class="fa fa-check"></i>
                        
                    </b-button>
                    <b-button v-if="d_gst_br_client_val==true" pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-primary" @click="edit_click(row.item)">
                            <i class="fa fa-pencil-alt"></i>
                    
                    </b-button>

                    
                    <b-button v-if="d_is_entete==true" pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-info" @click="Ckick_exportDox(row.item,1)">
                            <i class="fa fa-print"></i>
                    
                    </b-button>
                    
                    <b-button  pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-secondary" @click="Ckick_exportDox(row.item,0)">
                            <i class="fa fa-print"></i>
                    
                    </b-button>

                    <b-button v-if="d_is_print_pdf==true" pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-warning" @click="Ckick_exportDox(row.item,2)">
                            <i class="fa fa-print"></i> 
                    </b-button>

                    <b-button pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-info" @click="fetchListArts(row.item)">
                            <i class="fa fa-bars"></i>
                    
                    </b-button>
                </template>
        
        </b-table>
                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>

      </div>


    <!-- le modal -->
    <b-modal id="detailModal" :title=curentBN size="lg" hide-footer>


       <b-table 
        small 
        borderless
        striped 
        sticky-header
        :fields="fieldsArticle" 
        :items="ArticlesList"
        >

        
        </b-table>
              <div class="card border-info">
                        <div class=" d-flex justify-content-center pt-1 text-info">
                                <h5 class="font-weight-light ">Total Prix : {{total_price}} </h5>
                        </div>
              </div>
    </b-modal>
    <!-- les trucs public -->



    <!-- le modal -->
    <b-modal id="validerModal" title="Le paiement" hide-footer>
        <form @submit.prevent="handleSubmitReglerBn">

          <div class="mt-3 ml-2  text-secondary font-weight-light">Total Net (DH) : 
          <span class="d-inline text-info"> {{itemVal.totalTTC}} </span>  </div>

            <div class="form-group">
                            <label for="bank_compte_id">Compte :</label>
                            <select class="form-control" id="bank_compte_id" v-model="itemVal.bank_compte_id">
                            <option v-for="item in comptes" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>
                            </select>

            </div>

            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block mt-4">Valider</button>
            </div>
        </form>
    </b-modal>


<loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
import { ImageRun,HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export default {
    computed: {
            //3t pdf
    get_entete3t() {
      return this.$store.getters.get_entete3t;
    },  
    get_email_3t() {
      return this.$store.getters.get_email_3t;
    },
    get_contact_3t() {
      return this.$store.getters.get_contact_3t;
    },
    get_pied_pag_pdf3t1() {
      return this.$store.getters.get_pied_pag_pdf3t1;
    },
    get_pied_pag_pdf3t2() {
      return this.$store.getters.get_pied_pag_pdf3t2;
    },
    get_pied_pag_pdf3t3() {
      return this.$store.getters.get_pied_pag_pdf3t3;
    }, 
    //fin 3t pdf

                d_is_tresor() {
      return this.$store.getters.get_is_tresor;
    },
                    d_is_logo() {
      return this.$store.getters.get_is_logo;
    },
             d_logo_w() {
      return this.$store.getters.get_logo_w;
    },
            d_logo_h() {
      return this.$store.getters.get_logo_h;
    },
         d_img_logo_pdf() {
      return this.$store.getters.get_img_logo_pdf;
    }, 
    d_logo_w_pdf() {
      return this.$store.getters.get_logo_w_pdf;
    },
    d_logo_h_pdf() {
      return this.$store.getters.get_logo_h_pdf;
    },
    d_pied_pag_pdf1() {
      return this.$store.getters.get_pied_pag_pdf1;
    },
    d_pied_pag_pdf2() {
      return this.$store.getters.get_pied_pag_pdf2;
    },
    d_pied_pag_pdf3() {
      return this.$store.getters.get_pied_pag_pdf3;
    },
                     d_is_print_pdf() {
      return this.$store.getters.get_is_print_pdf;
    },   
    d_is_entete() {
      return this.$store.getters.get_is_entete;
    },
             d_logo_e() {
      return this.$store.getters.get_logo_e;
    },
            d_logo_p() {
      return this.$store.getters.get_logo_p;
    },
            d_logo_img() {
      return this.$store.getters.get_logo_img;
    }, 
                entet_pag() {

      if(this.$store.getters.get_entete)
      {
            return this.$store.getters.get_entete;
      }
      else
      {
            return '';
      }
    },
    sousentet_pag() {

      if(this.$store.getters.get_sousentete)
      {
            return this.$store.getters.get_sousentete;
      }
      else
      {
            return '';
      }
    },
    pied_pag() {

      if(this.$store.getters.get_pied)
      {
            return this.$store.getters.get_pied;
      }
      else
      {
            return '';
      }
    },
              rows_pagin() {
        return this.itemslist.length
      },
            user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    },
        user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },

        d_gst_br_client_val() {
      return this.$store.getters.get_gst_br_client_val;
    },
        total_price: function() {
      return this.ArticlesList.reduce((a, b) => +a + +(b.qte * b.prix), 0).toFixed(2);
    }
    },
    mounted(){
        this.fetchList()
    },
	data () {
		return {

            itemVal: {
                    bank_compte_id:'',
                    date: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    id_bon :'',
                    edit:false,
                    totalTTC:0},
            comptes:[],
            perPage: 10,
            currentPage: 1,
            fieldsArticle: [
                {key: 'ref', label: 'Réf'},
                {key: 'qte', label: 'Quantité'},
                 {key: 'unite', label: 'Unité'},
                {key: 'prix', label: 'Prix'},
                {key: 'nom_lot', label: 'Lot'},
                {key: 'description', label: 'Description'}
            ],
            ArticlesList:[],

            curentBN:'',

            itemslist: [],

            fields: [
                {key: 'nom', label: 'N°', sortable: true},
                {key: 'client', label: 'Client', sortable: true},
                {key: 'date', label: 'Date', sortable: true},
                {key: 'depot', label: 'Dépôt', sortable: true},
                {key: 'total_ttc', label: 'Total TTC', sortable: true},
                {key: 'valider', label: 'Valider', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }, sortable: true},
                {key: 'regler', label: 'Reglé', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }, sortable: true},
                {key: 'description', label: 'Description', sortable: true},
                 {key: 'user', label: 'Utilisateur', sortable: true},
                { key: 'actions', label: '' }
            ],
            //public trucs

            isLoading: false
		}
	},
    validations: {
            itemForm: {
                nom: {
                    required
                }
            }
    },
    methods :{

        export_pdf(rowitem)
{

      var imgData=this.d_img_logo_pdf;

      var imgData_w=this.d_logo_w_pdf;
      var imgData_h=this.d_logo_h_pdf;

    //3t pdf
    var local_entet_pag = this.get_entete3t
      var local_get_email_3t = this.get_email_3t
      var local_get_contact_3t = this.get_contact_3t


      var piedPg_local= this.get_pied_pag_pdf3t1;
      var piedPg_local2= this.get_pied_pag_pdf3t2;
      var piedPg_local3= this.get_pied_pag_pdf3t3
    //fin 3t pdf




      var totalttc_local = this.total_price.toString() + " DH";

      var n_local = rowitem.nom;
      var date_local = rowitem.date;

      var client_local = rowitem.client;
      var depot_local = rowitem.depot;

    const doc = new jsPDF();



    autoTable(doc, {
            body: [
                [
                {
                    content: '',
                    styles: {
                    halign: 'left'
                    }
                },
                {
                    content: '',
                    styles: {
                    halign: 'right'
                    }
                }
                ],
            ],

                didDrawCell: function (data) {

            if (data.section === 'body' && data.column.dataKey === 0) {


                    doc.addImage(imgData,'JPEG',data.cell.x,data.cell.y,imgData_w,imgData_h);
                    

            }


            if (data.section === 'body' && data.column.dataKey === 1) {
                doc.autoTable({
                startY: data.cell.y + 0.7,
                margin: { left: data.cell.x },
                tableWidth: data.cell.width - 2,
                styles: {
                    maxCellHeight: 5,
                },

                body: [
                    [
                        {
                        content: local_entet_pag,
                        styles: {
                            halign: 'right',
                            fontSize : 11,
                            textColor : '#000000',
                        
                            lineColor:'#89663c', 
                            lineWidth:{top: 0, right: 1.5, bottom: 0, left: 0},
                            cellPadding: {top: 0, right: 3, bottom: 0, left: 0},

                        },
                        
                        },


                    ]
            ],
            theme: 'grid'
                })
            

                                        
            }





            },



            theme: 'plain',
            styles: { minCellHeight: 18 },
            margin: {bottom: 0,left:7 ,right:7 ,top:9},
            columnStyles: {
                0: {
                    cellWidth: 80
                },
                1: {
                    cellWidth: 'auto'
                }}



            });


        //table nom bon 
            autoTable(doc, {
              body: [
                [
                  {
                    styles: {                   
                    lineColor:'#89663c', 
                    minCellHeight:10,
                    lineWidth:{top: 0, right: 1.5, bottom: 0, left: 0},
                    cellPadding: {top: 0, right: 3, bottom: 0, left: 0},

                  },
                  },
                
                ],
              ],

                        didDrawCell: (data) => {
                            if (data.section === 'body' && data.column.dataKey === 0) {
                               doc.setFillColor(22, 54, 92);
                               doc.setDrawColor(22, 54, 92);
                               doc.roundedRect( data.cell.x , data.cell.y, data.cell.width-3, data.cell.height, 0, 3, 'FD');
                                  doc.setFontSize(16)
                                  doc.setTextColor(255,255,255)
                                 doc.text('BON DE RETOUR', data.cell.x + 10, data.cell.y+7)

         

                            }


                        },


cellPadding:{left: 6 },
    margin: {left: 125,right:9 },

    startY: 39,

      theme: 'grid',

    });

        //ligne
            doc.setDrawColor(22,54,92);
          doc.setLineWidth(0.5);
          doc.line(7, 52, 202, 52);



    //client

    autoTable(doc, {
      body: [
        [
          {
            styles: {
              halign: 'right'
            }
          }
        ],
      ],

    didDrawCell: function (data) {
      if (data.section === 'body' && data.column.dataKey === 0) {
        doc.autoTable({

          startY: data.cell.y + 2,
          margin: { left: data.cell.x + 2 },
          tableWidth: data.cell.width - 4,
          styles: {
            maxCellHeight: 4,
            minCellWidth: 30
          },

        body: [
              [
                {
                  content: 'Client : ',
                  styles: {
                    halign: 'left',
                    fontStyle:'bold',
                    fontSize : 11,
                    textColor : '#2f64a4',
                  lineWidth:{top: 0, right: 0, bottom: 0, left: 0}
                  }
                },

                {
                  content: client_local,
                  styles: {
                    halign: 'left',
                    fontSize : 11,
                    textColor : '#000000',
                    lineColor:'#16365c', 
                  lineWidth:{top: 0, right: 0, bottom: 0, left: 0}

                  }
                }
              ],

              [
                {
                  content: 'Dépôt : ',
                  styles: {
                    halign: 'left',
                    fontStyle:'bold',
                    fontSize : 11,
                    textColor : '#2f64a4',
                    lineColor:'#16365c', 
                    lineWidth:{top: 0, right: 0, bottom: 0, left: 0}
                  }
                },

                {
                  content: depot_local+' ',
                  styles: {
                    halign: 'left',
                    fontSize : 11,
                    textColor : '#000000',
                    lineColor:'#16365c', 
                    lineWidth:{top: 0, right: 0, bottom: 0, left: 0},
                  }
                }
              ],

      ],

       theme: 'grid',

        })
      
                                 
      }





    },



      theme: 'plain',
       styles: { minCellHeight: 30.5 },

        margin: { right: 120 },
        startY: 56,


    });

    //data et num

    autoTable(doc, {
      body: [
        [
          {
            styles: {
              halign: 'right'
            }
          }
        ],
      ],

    didDrawCell: function (data) {
      if (data.section === 'body' && data.column.dataKey === 0) {
        doc.autoTable({

          startY: data.cell.y + 2,
          margin: { left: data.cell.x + 2 },
          tableWidth: data.cell.width - 4,
          styles: {
            maxCellHeight: 4,
            minCellWidth: 30
          },

        body: [
              [
                {
                  content: 'Date : ',
                  styles: {
                    halign: 'left',
                    fontStyle:'bold',
                    fontSize : 11,
                    textColor : '#2f64a4',
                  lineWidth:{top: 0, right: 0, bottom: 0, left: 0}
                  }
                },

                {
                  content: date_local,
                  styles: {
                    halign: 'left',
                    fontSize : 11,
                    textColor : '#000000',
                  lineWidth:{top: 0, right: 0, bottom: 0, left: 0}

                  }
                }
              ],

              [
                {
                  content: 'Bon N° : ',
                  styles: {
                    halign: 'left',
                    fontStyle:'bold',
                    fontSize : 11,
                    textColor : '#2f64a4',
                    lineWidth:{top: 0, right: 0, bottom: 0, left: 0}
                  }
                },

                {
                  content: n_local+' ',
                  styles: {
                    halign: 'left',
                    fontSize : 11,
                    textColor : '#000000',
                    lineWidth:{top: 0, right: 0, bottom: 0, left: 0}
                  }
                }
              ],

      ],

       theme: 'grid',

        })
      
                                 
      }





    },



      theme: 'plain',
       styles: { minCellHeight: 30.5 },

        margin: { left: 107 },
        startY: 56,


    });



      let article_body=[]

      this.ArticlesList.forEach(function (item) {


        article_body.push([item.ref, item.description, item.qte.toString(), item.unite, item.prix.toString(), Number(item.qte * item.prix).toFixed(2).toString()]);
      });





      autoTable(doc, {
      head: [['Référence', 'Description', 'Quantité', 'Unité', 'Prix','Total']],
      body: article_body,
      foot: [[{"content":"TOTAL TTC : "+totalttc_local,"colSpan":6}]],

      styles: { minCellWidth: 30 },
      textColor : '#000000',
      startY:80,
      //margin: {bottom: 40 },
      margin: {bottom: 45,left:8 ,right:8 ,top:8},
      didDrawPage: function (data) {

            // Footer
            //  var str = 'P:' + doc.internal.getNumberOfPages()

          doc.setFontSize(11)

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize
          var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()




    //foter

autoTable(doc, {
      body: [
        [
          {
            styles: {
              halign: 'right'
            },
           
          },
          {
            styles: {
              halign: 'right'
            },
           
          },
          {
            styles: {
              halign: 'right'
            },
           
          }
        ],
      ],

    didDrawCell: function (data) {
      if (data.section === 'body' && data.column.dataKey === 0) {
        doc.autoTable({

          startY: data.cell.y + 2,
          margin: { left: data.cell.x + 2 },
          tableWidth: data.cell.width - 4,
          styles: {
            maxCellHeight: 4,
            minCellWidth: 30
          },



        body: [
              [
                {
                  content: piedPg_local,
                  styles: {
                    halign: 'left',
                    fontSize : 9,
                    textColor : '#000000',
                  lineWidth:{top: 0, right: 0, bottom: 0, left: 0}

                  }
                }
              ],

      ],

       theme: 'grid',

        })
      
                                 
      }
      if (data.section === 'body' && data.column.dataKey === 1) {
        doc.autoTable({

          startY: data.cell.y + 2,
          margin: { left: data.cell.x + 2 },
          tableWidth: data.cell.width - 4,
          styles: {
            maxCellHeight: 4,
            minCellWidth: 30
          },

        body: [
              [
                {
                  content: piedPg_local2,
                  styles: {
                    halign: 'left',
                    fontSize : 9,
                    textColor : '#000000',
                  lineWidth:{top: 0, right: 0, bottom: 0, left: 0}

                  }
                }
              ],

      ],

       theme: 'grid',

        })
      
                                 
      }

      if (data.section === 'body' && data.column.dataKey === 2) {
        doc.autoTable({

          startY: data.cell.y + 2,
          margin: { left: data.cell.x + 2 },
          tableWidth: data.cell.width - 4,
          styles: {
            maxCellHeight: 4,
            minCellWidth: 30
          },

        body: [
              [
                {
                  content: piedPg_local3,
                  styles: {
                    halign: 'left',
                    fontSize : 9,
                    textColor : '#000000',
                  lineWidth:{top: 0, right: 0, bottom: 0, left: 0}

                  }
                }
              ],

      ],

       theme: 'grid',

        })
      
                                 
      }
    },



      theme: 'plain',
       styles: { minCellHeight: 10.5 },
       margin: {bottom: 0,left:8 ,right:8 ,top:0},
      //  margin: { left: 107 },
        startY: pageHeight - 43
        ,


    });


        doc.setTextColor(72,82,220)
          doc.text(local_get_email_3t, data.settings.margin.left+8, pageHeight - 10)
          doc.text(local_get_contact_3t, 170, pageHeight - 10)

          doc.setDrawColor(22,54,92);
          doc.setLineWidth(0.5);
          doc.line(10, pageHeight - 15, 200, pageHeight - 15);




    },

 
      theme: 'striped',
      headStyles:{
        textColor : '#FFFFFF',
        fontSize : 12,
        fillColor: '#16365c'
      },
      footStyles:{
        halign:'center',
        textColor : '#000000',
        fontSize : 13,
        fillColor: '#9ac2f2',
      cellPadding:{bottom: 5,left:0 ,right:0 ,top:5},
     // minCellHeight:15
      },
      showFoot: "lastPage"

    });






    doc.save('Retour Client '+n_local+'_'+client_local+'.pdf');




},
//fin export pdf




        Ckick_exportDox(rowitem,ent)
        {
                            this.isLoading=true;
                            this.$http.get('/detailBretourBon/'+rowitem.id)
                            .then((result) => {
                                        this.ArticlesList = result.data.data
                                        this.isLoading=false;


                            if(ent==0)
                            {
                                if(this.d_is_logo == true)
                                {
                                    this.exportDoxLogo(rowitem)
                                }
                                else
                                {
                                    this.exportDox(rowitem)
                                }

                            }
                            else if(ent==1)
                            {

                            this.exportDoxEntet(rowitem)
                            }
                            else if(ent==2)
                            {

                            this.export_pdf(rowitem)
                            }


                            })
                            .catch(error=> {
                                        console.log(error)
                                        this.isLoading=false;
                                        this.flashMessage.show({
                                        status: 'error',
                                        title: 'Erreur de Chargement',
                                        message: 'Impossible charger les données.. Actualisez et Réessayez'
                                        })
                            });
        },

 
        exportDoxEntet(rowitem)
        {

        let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        children: [
                                                    new TextRun({
                                                    text: "Référence",
                                                    color: '0f31b5',
                                                    bold: false,
                                                    font: "Segoe UI"
                                                    }),
                                                ],
                                                spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 6000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        children: [
                                                    new TextRun({
                                                    text: "Description",
                                                    color: '0f31b5',
                                                    bold: false,
                                                    font: "Segoe UI"
                                                    }),
                                                ],

                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        children: [
                                                    new TextRun({
                                                    text: "Quantité",
                                                    color: '0f31b5',
                                                    bold: false,
                                                    font: "Segoe UI"
                                                    }),
                                                ],

                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                                new TableCell({
                                    width: {
                                        size: 2500,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                children: [
                                                    new TextRun({
                                                    text: "Unité",
                                                    color: '0f31b5',
                                                    bold: false,
                                                    font: "Segoe UI"
                                                    }),
                                                ],

                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),
                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        children: [
                                                    new TextRun({
                                                    text: "Prix",
                                                    color: '0f31b5',
                                                    bold: false,
                                                    font: "Segoe UI"
                                                    }),
                                                ],

                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        children: [
                                                    new TextRun({
                                                    text: "Total",
                                                    color: '0f31b5',
                                                    bold: false,
                                                    font: "Segoe UI"
                                                    }),
                                                ],

                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                    ],
                })]

        this.ArticlesList.forEach(function(item){

        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: item.ref,
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],


                                        spacing: {
                                                    before: 100,
                                                    after: 100,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 6000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                            children: [
                                            new TextRun({
                                                text: item.description,
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],

                                                spacing: {
                                                    before: 10,
                                                    after: 10,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: item.qte.toString(),
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],

                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                                new TableCell({
                                    width: {
                                        size: 2500,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                            

                                                children: [
                                                new TextRun({
                                                text: item.unite,
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],

                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),
                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                            new TextRun({
                                                text: item.prix.toString(),
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                            new TextRun({
                                                text: Number(item.qte * item.prix).toFixed(2).toString(),
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],                         spacing: {
                                                    before: 10,
                                                    after: 10,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })

        datrw.push(
        r
        )
        });



        const table = new Table({

            rows:  datrw
        })

        //pied

                let datrw_salare_net =[new TableRow({
                    children: [
                            
                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({

                                                children: [
                                                    new TextRun({
                                                    text: "Total TTC",
                                                    color: '000000',
                                                    bold: false,
                                                    font: "Segoe UI"
                                                    }),
                                                ],

                                                    spacing: {
                                                    before: 80,
                                                    after: 80
                                                },
                                                heading: HeadingLevel.HEADING_1,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),


                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                            

                                                children: [
                                                    new TextRun({
                                                    text:  this.total_price.toString() + " DH",
                                                    color: '000000',
                                                    bold: false,
                                                    font: "Segoe UI"
                                                    }),
                                                ],


                                                heading: HeadingLevel.HEADING_1,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                            ],
                        })]



                const tablenet = new Table({

                    rows:  datrw_salare_net
                })

        //fin pie

        const doc = new Document({

            sections: [
                {



                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                            margin:{
                                        left:500,
                                        right:500
                                    }
                        },
                    },
        

        
                            headers: {

                                default: new Header({
                                    children: [

                                    new Paragraph({
                                            children: [
                                            ],

                                            heading: HeadingLevel.HEADING_5,
                                            alignment: AlignmentType.CENTER,
                                            spacing: {
                                                            after: this.d_logo_e,
                                                            before: 200
                                                        },
                                        }),



                                    ],
                                }),
                            },
                            footers: {
                                default: new Footer({
                                    children: [

                                new Paragraph({
                                            children: [

                                            ],

                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                            spacing: {
                                                            after: this.d_logo_p,
                                                            before: 200
                                                        },
                                        }),

                                    ],
                                }),
                            },




                    children: [

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Bon De Retour",
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                            }),
                            ],

                            heading: HeadingLevel.HEADING_1,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 200,
                                    before: 200
                                },
                        }),




                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "N° : "+rowitem.nom+"          Client : "+rowitem.client,
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                            }),

                                new TextRun({
                                text: "          Dépôt : "+rowitem.depot,
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                            heading: HeadingLevel.HEADING_3,
                            alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 100,
                                    before: 100
                                },
                        }),
                        
                        

                        new Paragraph({



                            children: [
                                new TextRun({
                                text: "De la part de : ",
                                color: '030633',
                                bold: false,
                                font: "Segoe UI"
                            }),
                                new TextRun({
                                text: rowitem.user,
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                            }),
                            new TextRun({
                                text: "          Date : ",
                                color: '030633',
                                bold: false,
                                font: "Segoe UI"
                            }),

                                new TextRun({
                                text: rowitem.date,
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                            heading: HeadingLevel.HEADING_3,
                            alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 100
                                },
                        }),

                        table,
                        new Paragraph({
                                    children: [

                                        new TextRun({
                                        text: "",
                                        color: '000000',
                                        bold: false,
                                        font: "Segoe UI"
                                    }),

                                    ],

                                    heading: HeadingLevel.HEADING_3,
                                                        alignment: AlignmentType.CENTER,

                                        spacing: {
                                            after: 50,
                                            before: 50
                                        },
                                }),

                        tablenet
                        
                        ]


                }]

        });








            Packer.toBlob(doc).then(blob => {

        saveAs(blob, rowitem.nom+".docx");

            });

        },
        exportDoxLogo(rowitem)
        {

            let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        children: [
                                                    new TextRun({
                                                    text: "Référence",
                                                    color: '0f31b5',
                                                    bold: false,
                                                    font: "Segoe UI"
                                                    }),
                                                ],
                                                spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 6000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        children: [
                                                    new TextRun({
                                                    text: "Description",
                                                    color: '0f31b5',
                                                    bold: false,
                                                    font: "Segoe UI"
                                                    }),
                                                ],

                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        children: [
                                                    new TextRun({
                                                    text: "Quantité",
                                                    color: '0f31b5',
                                                    bold: false,
                                                    font: "Segoe UI"
                                                    }),
                                                ],

                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                                new TableCell({
                                    width: {
                                        size: 2500,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                children: [
                                                    new TextRun({
                                                    text: "Unité",
                                                    color: '0f31b5',
                                                    bold: false,
                                                    font: "Segoe UI"
                                                    }),
                                                ],

                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),
                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        children: [
                                                    new TextRun({
                                                    text: "Prix",
                                                    color: '0f31b5',
                                                    bold: false,
                                                    font: "Segoe UI"
                                                    }),
                                                ],

                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                        children: [
                                                    new TextRun({
                                                    text: "Total",
                                                    color: '0f31b5',
                                                    bold: false,
                                                    font: "Segoe UI"
                                                    }),
                                                ],

                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                    ],
                })]

        this.ArticlesList.forEach(function(item){

        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: item.ref,
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],


                                        spacing: {
                                                    before: 100,
                                                    after: 100,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 6000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                            children: [
                                            new TextRun({
                                                text: item.description,
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],

                                                spacing: {
                                                    before: 10,
                                                    after: 10,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: item.qte.toString(),
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],

                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                                new TableCell({
                                    width: {
                                        size: 2500,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                            

                                                children: [
                                                new TextRun({
                                                text: item.unite,
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],

                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),
                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                            new TextRun({
                                                text: item.prix.toString(),
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                            new TextRun({
                                                text: Number(item.qte * item.prix).toFixed(2).toString(),
                                                color: '000000',
                                                bold: true,
                                                }),
                                                    ],                         spacing: {
                                                    before: 10,
                                                    after: 10,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })

        datrw.push(
        r
        )
        });



        const table = new Table({

            rows:  datrw
        })

        //pied

                let datrw_salare_net =[new TableRow({
                    children: [
                            
                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({

                                                children: [
                                                    new TextRun({
                                                    text: "Total TTC",
                                                    color: '000000',
                                                    bold: false,
                                                    font: "Segoe UI"
                                                    }),
                                                ],

                                                    spacing: {
                                                    before: 80,
                                                    after: 80
                                                },
                                                heading: HeadingLevel.HEADING_1,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),


                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                            

                                                children: [
                                                    new TextRun({
                                                    text:  this.total_price.toString() + " DH",
                                                    color: '000000',
                                                    bold: false,
                                                    font: "Segoe UI"
                                                    }),
                                                ],


                                                heading: HeadingLevel.HEADING_1,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                            ],
                        })]



                const tablenet = new Table({

                    rows:  datrw_salare_net
                })

        //fin pie

        const doc = new Document({

            sections: [
                {



                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                            margin:{
                                        left:500,
                                        right:500
                                    }
                        },
                    },
                            headers: {

                                default: new Header({
                                    children: [




                            new Paragraph({
                            children: [


                                new ImageRun({
                                    
                                data: Uint8Array.from(atob(this.d_logo_img), c =>
                                    c.charCodeAt(0)
                                ),
                                transformation: {
                                    width: this.d_logo_w,
                                    height: this.d_logo_h
                                },



                                })
                            ]
                            }),

                                    /* new Paragraph({
                                            children: [
                                    new TextRun({
                                        text: this.sousentet_pag,
                                        color: '000000',
                                        bold: false,
                                        })

                                            ],
                                        border: {
                                        bottom: {
                                                color: "000000",
                                                space: 4,
                                                value: "single",
                                                size: 10,
                                            }},
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        }),*/

                                    new Paragraph({
                                            children: [
                                            ],
                                        border: {
                                        bottom: {
                                                color: "000000",
                                                space: 1,
                                                value: "single",
                                                size: 10,
                                            }},
                                            heading: HeadingLevel.HEADING_5,
                                            alignment: AlignmentType.CENTER,
                                        }),



                                    ],
                                }),
                            },
                            footers: {
                                default: new Footer({
                                    children: [

                                new Paragraph({
                                            children: [
                                    new TextRun({
                                        text: this.pied_pag,
                                        color: '000000',
                                        bold: false,
                                        })

                                            ],
                                        border: {
                                        top: {
                                                color: "000000",
                                                space: 4,
                                                value: "single",
                                                size: 10,
                                            }},
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        }),

                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    children: ["Page : ", PageNumber.CURRENT],
                                                    color: '000000'
                                                }),
                                                new TextRun({
                                                    children: [" Sur ", PageNumber.TOTAL_PAGES],
                                                    color: '000000'
                                                }),
                                            ],
                                        }),
                                    ],
                                }),
                            },


                            children: [

        new Paragraph({



            children: [

                new TextRun({
                text: "Bon De Retour",
                color: '000000',
                bold: false,
                font: "Segoe UI"
            }),
            ],

            heading: HeadingLevel.HEADING_1,
                                alignment: AlignmentType.CENTER,

                spacing: {
                    after: 200,
                    before: 200
                },
        }),




        new Paragraph({



            children: [

                new TextRun({
                text: "N° : "+rowitem.nom+"          Client : "+rowitem.client,
                color: '000000',
                bold: false,
                font: "Segoe UI"
            }),

                new TextRun({
                text: "          Dépôt : "+rowitem.depot,
                color: '000000',
                bold: false,
                font: "Segoe UI"
            })
            ],

            heading: HeadingLevel.HEADING_3,
            alignment: AlignmentType.CENTER,

                spacing: {
                    after: 100,
                    before: 100
                },
        }),



        new Paragraph({



            children: [
                new TextRun({
                text: "De la part de : ",
                color: '030633',
                bold: false,
                font: "Segoe UI"
            }),
                new TextRun({
                text: rowitem.user,
                color: '000000',
                bold: false,
                font: "Segoe UI"
            }),
            new TextRun({
                text: "          Date : ",
                color: '030633',
                bold: false,
                font: "Segoe UI"
            }),

                new TextRun({
                text: rowitem.date,
                color: '000000',
                bold: false,
                font: "Segoe UI"
            })
            ],

            heading: HeadingLevel.HEADING_3,
            alignment: AlignmentType.CENTER,

                spacing: {
                    after: 300,
                    before: 100
                },
        }),

        table,
        new Paragraph({
                    children: [

                        new TextRun({
                        text: "",
                        color: '000000',
                        bold: false,
                        font: "Segoe UI"
                    }),

                    ],

                    heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,

                        spacing: {
                            after: 50,
                            before: 50
                        },
                }),

        tablenet

        ]



                }]

        });








            Packer.toBlob(doc).then(blob => {

        saveAs(blob, rowitem.nom+".docx");

            });

        },
        exportDox(rowitem)
        {

                let datrw =[new TableRow({
                            children: [
                                new TableCell({
                                    width: {
                                        size: 4000,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                children: [
                                                            new TextRun({
                                                            text: "Référence",
                                                            color: '0f31b5',
                                                            bold: false,
                                                            font: "Segoe UI"
                                                            }),
                                                        ],
                                                        spacing: {
                                                        before: 100,
                                                        after: 100
                                                    },
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),


                                new TableCell({
                                    width: {
                                        size: 6000,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                children: [
                                                            new TextRun({
                                                            text: "Description",
                                                            color: '0f31b5',
                                                            bold: false,
                                                            font: "Segoe UI"
                                                            }),
                                                        ],

                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                                new TableCell({
                                    width: {
                                        size: 2500,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({

                                                children: [
                                                            new TextRun({
                                                            text: "Quantité",
                                                            color: '0f31b5',
                                                            bold: false,
                                                            font: "Segoe UI"
                                                            }),
                                                        ],

                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),
                                        new TableCell({
                                            width: {
                                                size: 2500,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                            text: "Unité",
                                                            color: '0f31b5',
                                                            bold: false,
                                                            font: "Segoe UI"
                                                            }),
                                                        ],

                                                        heading: HeadingLevel.HEADING_3,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                new TableCell({
                                    width: {
                                        size: 2500,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                children: [
                                                            new TextRun({
                                                            text: "Prix",
                                                            color: '0f31b5',
                                                            bold: false,
                                                            font: "Segoe UI"
                                                            }),
                                                        ],

                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                                                new TableCell({
                                    width: {
                                        size: 3000,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({

                                                children: [
                                                            new TextRun({
                                                            text: "Total",
                                                            color: '0f31b5',
                                                            bold: false,
                                                            font: "Segoe UI"
                                                            }),
                                                        ],

                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),



                            ],
                        })]

                this.ArticlesList.forEach(function(item){

                let r = new TableRow({
                            children: [
                                new TableCell({
                                    width: {
                                        size: 4000,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: item.ref,
                                                        color: '000000',
                                                        bold: true,
                                                        }),
                                                            ],


                                                spacing: {
                                                            before: 100,
                                                            after: 100,
                                                            left: 10,
                                                            right:10
                                                        },
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),


                                new TableCell({
                                    width: {
                                        size: 6000,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                    children: [
                                                    new TextRun({
                                                        text: item.description,
                                                        color: '000000',
                                                        bold: true,
                                                        }),
                                                            ],

                                                        spacing: {
                                                            before: 10,
                                                            after: 10,
                                                            left: 10,
                                                            right:10
                                                        },
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                                new TableCell({
                                    width: {
                                        size: 2500,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: item.qte.toString(),
                                                        color: '000000',
                                                        bold: true,
                                                        }),
                                                            ],

                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),
                                        new TableCell({
                                            width: {
                                                size: 2500,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                    

                                                        children: [
                                                        new TextRun({
                                                        text: item.unite,
                                                        color: '000000',
                                                        bold: true,
                                                        }),
                                                            ],

                                                        heading: HeadingLevel.HEADING_3,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                new TableCell({
                                    width: {
                                        size: 2500,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                
                                                children: [
                                                    new TextRun({
                                                        text: item.prix.toString(),
                                                        color: '000000',
                                                        bold: true,
                                                        }),
                                                            ],
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                                new TableCell({
                                    width: {
                                        size: 3000,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                
                                                children: [
                                                    new TextRun({
                                                        text: Number(item.qte * item.prix).toFixed(2).toString(),
                                                        color: '000000',
                                                        bold: true,
                                                        }),
                                                            ],                         spacing: {
                                                            before: 10,
                                                            after: 10,
                                                            left: 10,
                                                            right:10
                                                        },
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),


                            ],
                        })

                datrw.push(
                r
                )
                });



                const table = new Table({

                    rows:  datrw
                })

        //pied

                let datrw_salare_net =[new TableRow({
                    children: [
                            
                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({

                                                children: [
                                                    new TextRun({
                                                    text: "Total TTC",
                                                    color: '000000',
                                                    bold: false,
                                                    font: "Segoe UI"
                                                    }),
                                                ],

                                                    spacing: {
                                                    before: 80,
                                                    after: 80
                                                },
                                                heading: HeadingLevel.HEADING_1,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),


                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                            

                                                children: [
                                                    new TextRun({
                                                    text:  this.total_price.toString() + " DH",
                                                    color: '000000',
                                                    bold: false,
                                                    font: "Segoe UI"
                                                    }),
                                                ],


                                                heading: HeadingLevel.HEADING_1,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                            ],
                        })]



                const tablenet = new Table({

                    rows:  datrw_salare_net
                })

        //fin pie




        const doc = new Document({

            sections: [
                {



                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                            margin:{
                                        left:500,
                                        right:500
                                    }
                        },
                    },
                            headers: {
                                default: new Header({
                                    children: [
                                new Paragraph({
                                            children: [
                                    new TextRun({
                                        text: this.entet_pag,
                                        color: '000000',
                                        bold: true,

                                        }),
                                            ],
                                            heading: HeadingLevel.TITLE,
                                            alignment: AlignmentType.CENTER,
                                        }),

                                new Paragraph({
                                            children: [
                                    new TextRun({
                                        text: this.sousentet_pag,
                                        color: '000000',
                                        bold: false,
                                        font: "Segoe UI"
                                        })

                                            ],
                                        border: {
                                        bottom: {
                                                color: "000000",
                                                space: 4,
                                                value: "single",
                                                size: 10,
                                            }},
                                            heading: HeadingLevel.HEADING_1,
                                            alignment: AlignmentType.CENTER,
                                        }),




                                    ],
                                }),
                            },
                            footers: {
                                default: new Footer({
                                    children: [

                                new Paragraph({
                                            children: [
                                    new TextRun({
                                        text: this.pied_pag,
                                        color: '000000',
                                        bold: false,
                                        })

                                            ],
                                        border: {
                                        top: {
                                                color: "000000",
                                                space: 4,
                                                value: "single",
                                                size: 10,
                                            }},
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        }),

                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    children: ["Page : ", PageNumber.CURRENT],
                                                    color: '000000'
                                                }),
                                                new TextRun({
                                                    children: [" Sur ", PageNumber.TOTAL_PAGES],
                                                    color: '000000'
                                                }),
                                            ],
                                        }),
                                    ],
                                }),
                            },



                            children: [

        new Paragraph({



            children: [

                new TextRun({
                text: "Bon De Retour",
                color: '000000',
                bold: false,
                font: "Segoe UI"
            }),
            ],

            heading: HeadingLevel.HEADING_1,
                                alignment: AlignmentType.CENTER,

                spacing: {
                    after: 200,
                    before: 200
                },
        }),




        new Paragraph({



            children: [

                new TextRun({
                text: "N° : "+rowitem.nom+"          Client : "+rowitem.client,
                color: '000000',
                bold: false,
                font: "Segoe UI"
            }),

                new TextRun({
                text: "          Dépôt : "+rowitem.depot,
                color: '000000',
                bold: false,
                font: "Segoe UI"
            })
            ],

            heading: HeadingLevel.HEADING_3,
            alignment: AlignmentType.CENTER,

                spacing: {
                    after: 100,
                    before: 100
                },
        }),



        new Paragraph({



            children: [
                new TextRun({
                text: "De la part de : ",
                color: '030633',
                bold: false,
                font: "Segoe UI"
            }),
                new TextRun({
                text: rowitem.user,
                color: '000000',
                bold: false,
                font: "Segoe UI"
            }),
            new TextRun({
                text: "          Date : ",
                color: '030633',
                bold: false,
                font: "Segoe UI"
            }),

                new TextRun({
                text: rowitem.date,
                color: '000000',
                bold: false,
                font: "Segoe UI"
            })
            ],

            heading: HeadingLevel.HEADING_3,
            alignment: AlignmentType.CENTER,

                spacing: {
                    after: 300,
                    before: 100
                },
        }),

        table,
        new Paragraph({
                    children: [

                        new TextRun({
                        text: "",
                        color: '000000',
                        bold: false,
                        font: "Segoe UI"
                    }),

                    ],

                    heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,

                        spacing: {
                            after: 50,
                            before: 50
                        },
                }),

        tablenet

        ]

                }]

        });








            Packer.toBlob(doc).then(blob => {

        saveAs(blob, rowitem.nom+".docx");

            });

        },

        fetchFormComptes_regler: function () {


            if(this.d_is_tresor==false)
            {

                    this.itemVal.bank_compte_id=-1;

                    this.handleSubmitReglerBn(); //appliquer etat

            }
            else
            {
            //comptes :
                    this.isLoading=true;
                    this.$http.get('/bankComptes')
                    .then((result) => {
                                this.comptes = result.data.data
                                this.isLoading=false;

                                 this.$root.$emit('bv::show::modal', 'validerModal')
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
            }
        },

        handleSubmitReglerBn()
        {

              if(!this.itemVal.bank_compte_id)
              {
                this.flashMessage.show({
                status: 'info',
                title: 'Sélectionnez un compte !!',
                })   
              }
            else
            {


  
                    //procedure du modification
                                this.isLoading=true;

                                this.$http.post('/validBretourClRegl',{
                                            date:this.itemVal.date,  
                                            user:this.user_name,
                                            bank_compte_id:this.itemVal.bank_compte_id,
                                            id_bon:this.itemVal.id_bon,
                                            type:0
                                    })
                                    .then(response => {

                                        this.isLoading=false;
                                        console.log(response.data)
                                        this.$bvModal.hide('validerModal')

                                            if(this.itemVal.edit)
                                            {
                                                                    this.$router.push({ 
                                                                    name: 'bnRetrClients', 
                                                                    params: { 
                                                                    ResivedId: this.itemVal.id_bon
                                                                    } 
                                                                    });
                                            }
                                            else
                                            {
                                                            let index = this.itemslist.findIndex(item => item.id === this.itemVal.id_bon)
                                                            this.itemslist.splice(index, 1)

                                            }
 
                                        this.flashMessage.show({
                                        status: 'success',
                                        title: 'Données modifiées',
                                        message: 'Les données sont modifiées correctement'
                                        })


                                        })
                                        .catch(error=> {console.log(error)
                                        this.isLoading=false;


                                        this.flashMessage.show({
                                        status: 'error',
                                        title: 'Erreur De Modification',
                                        message: 'Impossible  de modifier les données .. Réessayez !!'
                                    })
                                    });
                }




        },

        edit_click(item) {

                this.$confirm("Voulez-vous vraiment Devalider et modifier ce Bon ??")
                 .then(() => {

                                        this.itemVal.id_bon = item.id
                                        this.itemVal.edit = true

                                        this.itemVal.totalTTC = item.total_ttc

                                        this.fetchFormComptes_regler()

                })
                .catch(error=> {
                console.log(error)

                });


        },
        devald_click(item) {

                                    this.$confirm("Voulez-vous vraiment Devalider ce Bon??")
                                    .then(() => {

                                        this.itemVal.id_bon = item.id
                                        this.itemVal.edit = false

                                        this.itemVal.totalTTC = item.total_ttc

                                        this.fetchFormComptes_regler()

                                    })
                                    .catch(error=> {
                                    console.log(error)

                                        });




        },
        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/bretourType/1/1/'+this.user_dep)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },

        fetchListArts(row_bn)
        {
                    this.isLoading=true;
                    this.$http.get('/detailBretourBon/'+row_bn.id)
                    .then((result) => {
                                this.ArticlesList = result.data.data
                                this.isLoading=false;


                                this.curentBN=row_bn.nom + " ( "+row_bn.date+" )"
                                this.$root.$emit('bv::show::modal', 'detailModal')

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },
        NewBon()
        {
                    this.$router.push({ 
                        name: 'menuBnRetour'
                    });
        },
    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>
.var_color{
  color: #2faaa5;
}
</style>